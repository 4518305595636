import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  IconButton,
  StyleProps,
} from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';

interface HeadingScreenProps {
  title?: string | React.ReactNode;
  onBack?: () => void;
  children: JSX.Element | Array<JSX.Element | false | undefined>;
  link?: string;
  right?: React.ReactNode;
  styles?: StyleProps;
  containerStyle?: StyleProps;
}

export default function HeadingScreen(props: HeadingScreenProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    onBack,
    link,
    styles,
    title,
    right,
    containerStyle,
    children,
  } = props;

  const back = () => {
    if (onBack) {
      onBack();
    } else if (link) {
      navigate(link);
    } else if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const canGoBack = onBack || link || location.key !== 'default';

  return (
    <Box display="flex" flexDirection="column" height="100%" bgColor="white">
      <Box
        paddingX={4}
        paddingY={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        boxShadow="0px 0px 30px -15px rgba(0,0,0,0.75)"
        bgColor="redComanda.500"
        color="white"
        {...(styles || {})}
      >
        {canGoBack && (
          <IconButton
            icon={<MdArrowBack size="30px" />}
            mr={3}
            onClick={back}
            aria-label="Voltar"
            colorScheme="white"
            variant="ghost"
            size="md"
          />
        )}
        {title && typeof title === 'string' && (
        <Heading size="md" display="flex" alignItems="center">
          {title}
        </Heading>
        )}
        {title && typeof title !== 'string' && title}
        {right && <Box marginLeft="auto">{right}</Box>}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
        {...containerStyle}
      >
        {children}
      </Box>
    </Box>
  );
}

HeadingScreen.defaultProps = {
  title: undefined,
  onBack: undefined,
  right: undefined,
  styles: undefined,
  containerStyle: undefined,
  link: undefined,
};
