import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ControllerRenderProps,
} from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa';
import currency from 'currency.js';
import NumberFormat from 'react-number-format';
import React from 'react';

interface ItemOption {
    id: string;
    qty?: number;
}

interface ItemSetupFields {
    options?: Array<Array<ItemOption>>;
    qty: number;
    tableNumber?: string;
    comments?: string;
    customFields: Array<Pagcomanda.CustomField>;
}

interface CheckGroupSectionProps {
    section: Pagcomanda.ItemOptions;
    sectionKey: number;
    field: ControllerRenderProps<ItemSetupFields, `options.${number}`>;
    isDisabled: boolean;
    isInvalid: boolean;
    errors: any;
}

interface FieldProps {
    value: ItemOption[];
    onChange: (options: ItemOption[]) => void;
}

export default function CheckGroupSection(props: CheckGroupSectionProps) {
  const {
    section, sectionKey, field, isDisabled, isInvalid, errors,
  } = props;
  const { minOptions, maxOptions } = section;
  const { value = [], onChange }: FieldProps = field;

  return (
    <Box
      paddingX={3}
      paddingTop={2}
      mt={1}
      mb={3}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius={5}
    >
      <Text fontSize="md" fontWeight="bold">
        {section.name}
      </Text>
      <Text fontSize="xs" color="gray.500">
        {section.description}
      </Text>

      <CheckboxGroup
        size="lg"
        value={value.map((i) => i.id)}
        onChange={(values: number[]) => {
          const newValue = values.map((v) => {
            const newQty = value.find((i) => i.id === v.toString())?.qty ?? 1;

            const option: ItemOption = {
              id: v.toString(),
              qty: newQty,
            };
            return option;
          }).filter((x) => (x?.qty ?? 0) > 0);

          onChange(newValue);
        }}
      >
        <Text fontSize="xs" margin={0} color="redComanda">
          {minOptions !== undefined && maxOptions !== undefined && minOptions === 1 && maxOptions === 1 && 'Obrigatório'}
          {minOptions !== undefined && maxOptions !== undefined && minOptions > 1
            && maxOptions === minOptions
            && `Selecione ${minOptions} opções.`}
          {minOptions === undefined && maxOptions !== undefined && maxOptions > 1 && `Selecione até ${maxOptions} opções.`}
        </Text>
        <VStack alignItems="start" spacing={3} my={3}>
          {section.options.map((option, optionKey) => {
            const optionKeyString: string = optionKey.toString();
            const optionsSelected = value.reduce((sum, v) => sum + (v?.qty ?? 0), 0);
            const disabled: boolean = maxOptions !== undefined && maxOptions > 0
            && Array.isArray(value)
            && optionsSelected >= maxOptions
            && value.findIndex((item) => item.id === optionKeyString) === -1;

            return (
              <Flex key={`check-group-section-${option.title}`} direction="row" width="100%" alignItems="center">
                {!isDisabled && (
                  <>
                    <Checkbox
                      id={`option_${sectionKey}_${optionKey}`}
                      key={`option_${sectionKey}_${optionKeyString}`}
                      value={optionKeyString}
                      isInvalid={isInvalid}
                      isDisabled={disabled}
                      hidden={option.allowQty}
                    />
                    <InputGroup hidden={!option.allowQty} size="sm" width="7em" alignItems="center" borderWidth={1}>
                      <InputLeftAddon
                        paddingX={2}
                        paddingY={0}
                        mr={2}
                        border={0}
                        style={{ opacity: disabled ? 0.3 : 1 }}
                        onClick={() => {
                          if (disabled) return;

                          const actualQty = value.find((i) => i.id === optionKeyString)?.qty ?? 0;
                          const qty = actualQty > 0 ? actualQty - 1 : 0;
                          const newValue = [...value];

                          const exists = newValue.findIndex((i) => i.id === optionKeyString);

                          if (exists > -1) {
                            newValue[exists].qty = qty;
                          } else {
                            newValue.push({
                              id: optionKeyString,
                              qty,
                            });
                          }

                          onChange(newValue.filter((x) => (x?.qty ?? 0) > 0));
                        }}
                      >
                        <Icon as={FaMinus} color="redComanda" />
                      </InputLeftAddon>
                      <NumberFormat
                        allowNegative={false}
                        decimalScale={0}
                        customInput={Input}
                        style={{ margin: 'auto', opacity: disabled ? 0.3 : 1 }}
                        displayType="text"
                        value={value.find((i) => i.id === optionKeyString)?.qty ?? 0}
                      />
                      <InputRightAddon
                        paddingX={2}
                        paddingY={0}
                        ml={2}
                        border={0}
                        style={{
                          opacity: disabled || optionsSelected >= (maxOptions ?? 1) ? 0.3 : 1,
                        }}
                        onClick={() => {
                          if (disabled || optionsSelected >= (maxOptions ?? 1)) return;

                          const actualQty = value.find((i) => i.id === optionKeyString)?.qty ?? 0;
                          const qty = actualQty > 0 ? actualQty + 1 : 1;
                          const newValue = [...value];
                          const exists = newValue.findIndex((i) => i.id === optionKeyString);

                          if (exists > -1) {
                            newValue[exists].qty = qty;
                          } else {
                            newValue.push({
                              id: optionKeyString,
                              qty,
                            });
                          }

                          onChange(newValue.filter((x) => (x?.qty ?? 0) > 0));
                        }}
                      >
                        <Icon as={FaPlus} color="redComanda" />
                      </InputRightAddon>
                    </InputGroup>
                  </>
                )}
                <FormLabel htmlFor={`option_${sectionKey}_${optionKey}`} margin={0} mx={3}>
                  <span>{option?.title ?? option?.description ?? 'Sem descrição'}</span>
                  {option?.title && option?.description && (
                    <Text as="span" mt={0} display="block" fontSize="sm" fontWeight="normal" color="GrayText">
                      {option.description}
                    </Text>
                  )}
                </FormLabel>
                {option?.price !== undefined && option.price >= 0 && (
                <Text as="span" fontSize="xs" ml={2} color="whatsapp.700">
                  +
                  {' '}
                  {currency(option.price, { symbol: 'R$', decimal: ',', separator: '.' }).format()}
                </Text>
                )}
              </Flex>
            );
          })}
        </VStack>
      </CheckboxGroup>

      {section?.minOptions !== undefined && errors?.options?.[sectionKey] && (
        <Text color="red.500" mb={3} fontSize="sm">
          {errors?.options?.[sectionKey].type === 'required' && 'Você precisa escolher ao menos uma opção.'}
          {errors?.options?.[sectionKey].message}
        </Text>
      )}
    </Box>

  );
}
