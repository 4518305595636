/* eslint-disable no-loop-func */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import currency from 'currency.js';
import { db } from 'firebaseApp';
import EscPosEncoder from 'esc-pos-encoder';
import firebase from 'firebase';
import {
  firestoreComandaItemsConverter,
} from 'contexts/session';
import { firestoreComandaUseConverter } from 'contexts/comanda';
import removeAccents from 'remove-accents';
import Swal from 'sweetalert2';

export type PrinterType = 'usb' | 'bluetooth';
export type PrintType = 'default' | 'kitchen';

interface PrinterProviderProps {
    children: React.ReactNode;
    values: {
      usingVenue?: string;
    }
}

export type StartPrintFunction = (content: ArrayBufferLike) => Promise<void>;

interface PrinterContextValues {
  autoPrint: boolean;
  setAutoPrint: (isEnabled: boolean) => void;
  printerType: PrinterType;
  setPrinterType: React.Dispatch<React.SetStateAction<PrinterType>>;
  startPrint: StartPrintFunction;
}

const PrinterContext = React.createContext<PrinterContextValues>({
  autoPrint: false,
  setAutoPrint: () => { //
  },
  printerType: 'usb',
  setPrinterType: () => { //
  },
  startPrint: async () => {
    // void
  },
});

interface PrintNewItems {
  newItems: firebase.firestore.QuerySnapshot<Pagcomanda.ComandaItem>;
  startPrint: StartPrintFunction;
}
interface RequestPrintProps {
  startPrint: StartPrintFunction;
  printType?: PrintType;
  comanda?: Pagcomanda.Comanda | null;
  comandaRef?: firebase.firestore.DocumentReference<Pagcomanda.Comanda>;
  venue?: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>;
  comandaItems?: firebase.firestore.QuerySnapshot<Pagcomanda.ComandaItem>;
}

function calculateOrderId(comandaData?: Pagcomanda.Comanda) {
  return comandaData?.createdAt.toDate().getTime().toString().substr(-7)
    .substr(0, 6);
}

export function requestPrint({
  startPrint,
  printType,
  comanda,
  comandaRef,
  venue,
  comandaItems,
}: RequestPrintProps) {
  const encoder = new EscPosEncoder();

  let data: EscPosEncoder = encoder
    .initialize();

  /** New print type */
  if (comanda && printType !== 'kitchen' && venue?.data()?.OptSimples === true) {
    console.log(venue?.get('CommercialName') ?? '');
    console.log(venue?.get('Identity'));
    console.log(`Comanda aberta em ${(comanda?.createdAt?.toDate() ?? new Date()).toLocaleString()}`);
    console.log(`Impresso em ${new Date().toLocaleString()}`);
    console.log(''.padStart(48, '-'));

    data = data
      .align('center')
      .bold()
      .line(removeAccents(venue?.get('CommercialName') ?? ''))
      .bold()
      .size('small')
      .line(venue?.get('Identity'))
      .line(
        `Comanda aberta em ${(comanda?.createdAt?.toDate() ?? new Date()).toLocaleString()}`,
      )
      .line(
        `Impresso em ${new Date().toLocaleString()}`,
      )
      .align('left')
      .size('normal')
      .line(''.padStart(48, '-'));

    console.log(`Comanda ${calculateOrderId(comanda)}`);
    console.log(''.padStart(48, '-'));
    data = data
      .align('center')
      .size('large')
      .bold(true)
      .line(`Comanda ${calculateOrderId(comanda)}`)
      .bold(false)
      .size('normal')
      .line(''.padStart(48, '-'))
      .newline()
      .align('left');

    let comandaTotal = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
    let comandaPaid = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });

    comandaItems?.docs.forEach((doc, index) => {
      const item = doc.data();

      let total = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
      total = total.add(currency(item.price)
        .add(item.additionalPrice ?? 0).multiply(item.qty));

      if (total.value > 0) {
        comandaTotal = comandaTotal.add(total);
      }
      comandaPaid = comandaPaid.add(total);

      const description = `${item.qty} x ${removeAccents(item?.description ?? '')}`.toUpperCase();
      const formattedPrice = total.format();

      if (description.length + formattedPrice.length > 48) {
        console.log(description);
        data = data.bold().line(description).bold();
        console.log(formattedPrice.padStart(48, ' '));
        data = data.line(formattedPrice.padStart(48, ' '));
      } else {
        const spaceRemaining = 48 - description.length - formattedPrice.length;
        console.log(`${description}${''.padStart(spaceRemaining, ' ')}${formattedPrice}`);
        data = data.bold().line(`${description}${''.padStart(spaceRemaining, ' ')}${formattedPrice}`).bold();
      }

      (item?.options ?? []).forEach((optionalItem) => {
        optionalItem.options.forEach((option) => {
          console.log(`    ${option.qty} x ${removeAccents(option?.title ?? '')}`);
          data = data.line(`    ${option.qty} x ${removeAccents(option?.title ?? '')}`);
        });
      });

      if (comandaItems.docs.length > index + 1) {
        console.log('');
        data = data.newline();
      }
    });

    console.log(''.padStart(48, '-'));
    data = data.line(''.padStart(48, '-'));

    const finalTotalDescription = 'Total da comanda';
    const finalTotalFormatted = comandaTotal.format();

    if (finalTotalDescription.length + finalTotalFormatted.length > 48) {
      console.log(finalTotalDescription);
      data = data.bold().line(finalTotalDescription).bold();
      console.log(finalTotalFormatted.padStart(48, ' '));
      data = data.bold().line(finalTotalFormatted.padStart(48, ' ')).bold();
    } else {
      const spaceRemaining = 48 - finalTotalDescription.length - finalTotalFormatted.length;
      console.log(`${finalTotalDescription}${''.padStart(spaceRemaining, ' ')}${finalTotalFormatted}`);
      data = data.bold().line(`${finalTotalDescription}${''.padStart(spaceRemaining, ' ')}${finalTotalFormatted}`).bold();
    }

    if (comandaPaid.value > 0) {
      const finalPaymentDescription = 'Saldo a pagar';
      const finalPaymentFormatted = comandaPaid.format();

      if (finalPaymentDescription.length + finalPaymentFormatted.length > 48) {
        console.log(finalPaymentDescription);
        data = data.bold().line(finalPaymentDescription).bold();
        console.log(finalPaymentFormatted.padStart(48, ' '));
        data = data.bold().line(finalPaymentFormatted.padStart(48, ' ')).bold();
      } else {
        const spaceRemaining = 48 - finalPaymentDescription.length - finalPaymentFormatted.length;
        console.log(`${finalPaymentDescription}${''.padStart(spaceRemaining, ' ')}${finalPaymentFormatted}`);
        data = data.bold().line(`${finalPaymentDescription}${''.padStart(spaceRemaining, ' ')}${finalPaymentFormatted}`).bold();
      }
    }

    console.log(''.padStart(48, '-'));
    data = data.line(''.padStart(48, '-'));

    if (comanda?.metadata) {
      Object.keys(comanda.metadata).forEach((meta) => {
        console.log(removeAccents(comanda?.metadata?.[meta]?.label ?? 'Campo personalizado'));
        console.log(removeAccents(comanda?.metadata?.[meta]?.value ?? '(em branco)').toUpperCase());
        data = data
          .bold()
          .line(removeAccents(comanda?.metadata?.[meta]?.label ?? 'Campo personalizado'))
          .bold()
          .line(removeAccents(comanda?.metadata?.[meta]?.value ?? '(em branco)').toUpperCase())
          .align('left');
      });
    }

    console.log(''.padStart(48, '-'));
    data = data.line(''.padStart(48, '-'));

    if (comandaPaid.value > 0) {
      data = data
        .align('center')
        .qrcode(`https://pagcomanda.com/${comanda?.billType ?? 'p'}/${comandaRef?.id}`, 2, 8, 'm')
        .line(comanda?.securityCode ?? '')
        .line(''.padStart(48, '-'))
        .line('Aponte a camera do seu celular')
        .line('para o QR Code nesta comanda')
        .line('para consultar os itens lancados')
        .line('e fazer o pagamento online.')
        .newline();
    }

    data = data
      .align('center')
      .newline()
      .bold()
      .line('PAGCOMANDA.COM')
      .bold()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut();

    const printBuffer = data
      .encode().buffer;

    return startPrint(printBuffer);
  }
  /** End new print type */

  if (printType !== 'kitchen') {
    data = data
      .align('center')
      .bold()
      .line(removeAccents(venue?.get('CommercialName') ?? ''))
      .bold()
      .size('small')
      .line(venue?.get('Identity'))
      .line(
        `Comanda aberta em ${(comanda?.createdAt?.toDate() ?? new Date()).toLocaleString()}`,
      )
      .line(
        `Impresso em ${new Date().toLocaleString()}`,
      )
      .line('--------------------------------')
      .align('left')
      .size('normal')
      .newline()
      .newline();
  }

  if (comanda) {
    data = data
      .align('center')
      .size('large')
      .bold(true)
      .line(`Comanda - ${calculateOrderId(comanda)}`)
      .bold(false)
      .size('normal')
      .newline()
      .align('left');
  }

  if (printType !== 'kitchen' && comanda?.title) {
    data = data
      .align('center')
      .line(comanda?.title ?? 'Mesa')
      .line('--------------------------------')
      .newline()
      .align('left');
  }

  let total = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
  let payments = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
  let totalItems = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
  let finalItems: Pagcomanda.ComandaItem[] = [];

  if (printType !== 'kitchen') {
    // Group items
    const groupedItems: {
      [productId: string]: {
        productId?: string;
        additionalPrice?: number,
        createdAt: Pagcomanda.Timestamp;
        description?: string;
        qty: number;
        price: number;
        unity?: string;
        type: Pagcomanda.ItemType;
      }
    } = {};

    comandaItems?.docs.forEach((doc) => {
      const item = doc.data();

      if (!item.isCanceled) {
        total = total.add(currency(item.price)
          .add(item.additionalPrice ?? 0).multiply(item.qty));
      }

      if (item.type === 'payment' && !item.isCanceled) {
        payments = payments
          .add(currency(item.price)
            .add(item.additionalPrice ?? 0))
          .multiply(item.qty);
      } else if (!item.isCanceled) {
        totalItems = totalItems
          .add(currency(item.price)
            .add(item.additionalPrice ?? 0))
          .multiply(item.qty);
      }

      if (item.productId && !groupedItems[item.productId] && !item.isCanceled) {
        groupedItems[item.productId] = {
          productId: item.productId,
          description: item.description,
          qty: currency(item.qty).value,
          createdAt: item.createdAt,
          additionalPrice: 0,
          unity: item.unity,
          type: item.type,
          price: currency(item?.originalPrice ?? item.price).value,
        };
      } else if (item.productId && !item.isCanceled) {
        // groupedItems[item.productId]
        //   .additionalPrice = currency(groupedItems[item.productId]?.additionalPrice ?? 0)
        //     .add(item?.additionalPrice ?? 0).value;
        groupedItems[item.productId].qty += currency(item.qty).value;
      }

      if (!item.isCanceled && item?.options) {
        item.options.forEach((itemOption) => {
          itemOption.options.forEach((itemSelectedOption) => {
            const optionName = `${item.productId}${itemOption.name}${itemSelectedOption?.title ?? itemSelectedOption?.description ?? 'Sem descrição'}`;
            if (!groupedItems[optionName]) {
              groupedItems[optionName] = {
                productId: optionName,
                description: `${itemOption.name} - ${itemSelectedOption?.title ?? itemSelectedOption?.description ?? 'Sem descrição'}`,
                qty: itemSelectedOption.qty ?? 1,
                createdAt: item.createdAt,
                additionalPrice: 0,
                unity: '',
                type: 'purchase',
                price: itemSelectedOption?.price ?? 0,
              };
            } else if (groupedItems[optionName]) {
              groupedItems[optionName].qty += itemSelectedOption.qty ?? 1;
            }
          });
        });
      }

      if (item.type === 'payment' && !item.isCanceled && !groupedItems[item?.category ?? 'payment']) {
        groupedItems[item?.category ?? 'payment'] = {
          description: item.description,
          qty: item.qty,
          createdAt: item.createdAt,
          unity: item.unity,
          type: item.type,
          price: item.price,
        };
      } else if (item.type === 'payment' && !item.isCanceled) {
        groupedItems[item?.category ?? 'payment'].price = currency(groupedItems[item?.category ?? 'payment'].price).add(item.price).value;
      }
    });

    finalItems = Object.values(groupedItems)
      .sort(
        (a, b) => (removeAccents(a?.description ?? '').toLocaleLowerCase()
      > removeAccents(b?.description ?? '').toLowerCase() ? 1 : -1),
      );
  } else if (comandaItems?.docs) {
    finalItems = comandaItems.docs.map((doc) => doc.data());
  }

  finalItems.forEach((item) => {
    if (!item || item.isCanceled) return;

    const itemPrice = currency(item.price, { symbol: 'R$', decimal: ',', separator: '.' });
    const additionalPrice = currency(item?.additionalPrice ?? 0, { symbol: 'R$', decimal: ',', separator: '.' });

    if (printType === 'kitchen' && !item.isCanceled) {
      total = total.add(currency(item.price).add(item.additionalPrice ?? 0).multiply(item.qty));
    }

    if (printType === 'kitchen' && item.type === 'payment' && !item.isCanceled) {
      payments = payments
        .add(currency(item.price)
          .add(item.additionalPrice ?? 0)
          .multiply(item.qty));
    } else if (printType === 'kitchen' && !item.isCanceled) {
      totalItems = totalItems
        .add(currency(item.price)
          .add(item.additionalPrice ?? 0)
          .multiply(item.qty));
    }

    if (printType === 'kitchen' && item.type === 'payment') {
      return;
    }

    if (printType === 'kitchen' && comanda?.title) {
      data = data
        .align('center')
        .bold(true)
        .line(comanda?.title)
        .bold(false)
        .line('--------------------------------')
        .newline()
        .align('left');
    }

    if (printType === 'kitchen') {
      if (item.createdAt) data = data.bold(true).line(`${item.createdAt.toDate().toLocaleString()}`).bold(false);
      if (item?.userName) data = data.bold(true).line(`Pedido por: ${removeAccents(item.userName)}`).bold(false);

      if (comanda) {
        data = data
          .size('large')
          .bold(true)
          .line(`${comanda?.metadata?.orderType?.description ?? comanda?.metadata?.orderType?.label ?? 'DELIVERY'} - ${calculateOrderId(comanda)}`)
          .bold(false)
          .size('normal');
      }

      if (item?.tableNumber) {
        data = data
          .size('large')
          .bold(true)
          .line(`Mesa: ${removeAccents(item.tableNumber)}`)
          .bold(false)
          .size('normal');
      }

      data = data
        .newline()
        .newline()
        .bold(true)
        .size('large')
        .line(`${item.qty} x ${removeAccents(item?.description ?? 'Item sem descrição').toUpperCase()}`)
        .size('normal')
        .bold(false);
    } else if (!item.isCanceled) {
      data = data
        .bold(true)
        .line(`${item.qty} x ${removeAccents(item?.description ?? 'Item sem descrição').toUpperCase()}`)
        .bold(false);
    }

    if (item.options && item.options.length > 0 && printType !== 'kitchen' && !item.isCanceled) {
      item.options.forEach((section) => {
        section.options.forEach((option) => {
          data = data
            .line(`${option.qty} x ${removeAccents(option?.title ?? option?.description ?? 'Sem descrição')}${option.price ? ` x ${currency(option.price ?? 0, { symbol: 'R$', decimal: ',', separator: '.' }).format()}` : ''}`);
        });
      });
    }

    if (printType !== 'kitchen' && !item.isCanceled) {
      data = data
        .line(
          `${itemPrice.format()} x ${item.qty} ${item.unity}${additionalPrice.value > 0 ? ` + ${additionalPrice.format()}` : ''} = ${
            itemPrice.multiply(item.qty).add(additionalPrice).format()}`,
        );
    }

    if (item.options && item.options.length > 0 && printType === 'kitchen') {
      data = data.newline();
      item.options.forEach((section) => {
        data = data.line(`${removeAccents(section.name)}`);
        section.options.forEach((option) => {
          if (option?.allowQty) {
            data = data
              .bold(true)
              .size('large')
              .line(`${option.qty} x ${removeAccents(option?.title ?? option?.description ?? 'Sem descrição')}`)
              .size('normal')
              .bold(false);
          } else {
            data = data
              .bold(true)
              .size('large')
              .line(`${removeAccents(option?.title ?? option?.description ?? 'Sem descrição')}`)
              .size('normal')
              .bold(false);
          }
        });
        data = data.newline();
      });
    }

    if (item.customFields && item.customFields.length > 0 && printType === 'kitchen') {
      data = data.newline();
      item.customFields.forEach((customField) => {
        data = data.line(`${removeAccents(customField.label)}`);
        data = data
          .bold(true)
          .size('large')
          .line(`${removeAccents((customField?.value ?? '(em branco)').toString())}`)
          .size('normal')
          .bold(false);
        data = data.newline();
      });
    }

    if (item.comments) data = data.newline().line(removeAccents(item.comments));

    data = data.newline();

    if (printType === 'kitchen') {
      data = data
        .newline()
        .newline()
        .newline()
        .newline()
        .newline()
        .newline()
        .cut('partial')
        .newline();
    }
  });

  if (printType !== 'kitchen') {
    data = data
      .bold()
      .line('Total da comanda')
      .bold()
      .align('right')
      .line(totalItems.format())
      .align('left');

    if (payments.value < 0) {
      data = data
        .bold()
        .line('Pagamentos')
        .bold()
        .align('right')
        .line(payments.format())
        .align('left');
    }

    if (total.value > 0) {
      data = data
        .bold()
        .line('Saldo a pagar')
        .bold()
        .align('right')
        .line(total.format())
        .align('left');
    }

    data = data.newline();

    if (comanda?.metadata) {
      Object.keys(comanda.metadata).forEach((meta) => {
        data = data
          .bold()
          .line(removeAccents(comanda?.metadata?.[meta]?.label ?? 'Campo personalizado'))
          .bold()
          .line(removeAccents(comanda?.metadata?.[meta]?.value ?? '(em branco)').toUpperCase())
          .align('left');
      });
    }

    if (total.value > Math.abs(payments.value)) {
      data = data.align('center').line('--------------------------------');
      data = data
        .align('center')
        .qrcode(`https://pagcomanda.com/${comanda?.billType ?? 'p'}/${comandaRef?.id}`, 2, 8, 'm')
        .line(comanda?.securityCode ?? '')
        .line('--------------------------------')
        .line('Aponte a camera do seu celular')
        .line('para o QR Code nesta comanda')
        .line('para consultar os itens lancados')
        .line('e fazer o pagamento online.')
        .newline();
    }

    data = data
      .align('center')
      .newline()
      .bold()
      .line('PAGCOMANDA.COM')
      .bold()
      .newline()
      .newline()
      .newline()
      .newline()
      .cut();
  }

  const printBuffer = data
    .encode()
    .buffer;

  return startPrint(printBuffer);
}

const printNewItems = async ({ newItems, startPrint }: PrintNewItems) => {
  if (newItems.empty) return;

  const encoder = new EscPosEncoder();
  let data = encoder
    .initialize();

  // eslint-disable-next-line no-restricted-syntax
  for (const doc of newItems.docChanges()) {
    const docPath = doc.doc.ref.path;

    if (doc.type !== 'added') return;
    if (docPath.startsWith('bills/')) return;

    const item = doc.doc.data();

    if (item.type === 'payment') return;

    // eslint-disable-next-line no-await-in-loop
    const comanda = await doc.doc.ref.parent
      .parent?.withConverter(firestoreComandaUseConverter).get();

    data = data.newline()
      .newline()
      .newline();

    if (item.createdAt) data = data.bold(true).line(`Pedido criado: ${item.createdAt.toDate().toLocaleString()}`).bold(false);
    if (item?.userName) data = data.bold(true).line(`Pedido por: ${removeAccents(item.userName)}`).bold(false);

    data = data
      .size('large')
      .bold(true)
      .line(`${comanda?.data()?.metadata?.orderType?.description ?? comanda?.data()?.metadata?.orderType?.label ?? 'DELIVERY'} - ${calculateOrderId(comanda?.data())}`)
      .bold(false)
      .size('normal');

    if (item?.tableNumber) {
      data = data
        .size('large')
        .bold(true)
        .line(`Mesa: ${removeAccents(item.tableNumber)}`)
        .bold(false)
        .size('normal');
    }

    data = data
      .newline()
      .bold(true)
      .size('large')
      .line(`${item.qty} ${item.unity} x ${removeAccents(item?.description ?? 'Item sem descrição').toUpperCase()}`)
      .size('normal')
      .bold(false);

    if (item.options && item.options.length > 0) {
      data = data.newline();
      item.options.forEach((section) => {
        data = data.line(`${removeAccents(section.name)}`);
        section.options.forEach((option) => {
          if (option?.allowQty) {
            data = data
              .bold(true)
              .size('large')
              .line(`${option.qty} x ${removeAccents(option?.title ?? option?.description ?? 'Sem descrição')}`)
              .size('normal')
              .bold(false);
          } else {
            data = data
              .bold(true)
              .size('large')
              .line(`${removeAccents(option?.title ?? option?.description ?? 'Sem descrição')}`)
              .size('normal')
              .bold(false);
          }
        });
        data = data.newline();
      });
    }

    if (item.customFields && item.customFields.length > 0) {
      data = data.newline();
      item.customFields.forEach((customField) => {
        data = data.line(`${removeAccents(customField.label)}`);
        data = data
          .bold(true)
          .size('large')
          .line(`${removeAccents((customField?.value ?? '(em branco)').toString())}`)
          .size('normal')
          .bold(false);
        data = data.newline();
      });
    }

    if (item.comments) {
      data = data
        .newline()
        .line('Comentarios do cliente:')
        .size('large')
        .line(removeAccents(item.comments))
        .size('normal');
    }

    data = data
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .newline()
      .align('center')
      .line('-*-  -*-  -*-')
      .align('left')
      .cut();
  }

  startPrint(data.encode().buffer);
};

let device: USBDevice | undefined;
let endpointNumber = 1;

function PrinterProvider({ children, values }: PrinterProviderProps) {
  const [printerType, setPrinterType] = useState<PrinterType>('usb');
  const [autoPrint, setAutoPrint] = useState<boolean>(false);

  const printQueue: ArrayBufferLike[] = [];

  const getDevices = async () => {
    if (!device) {
      let devices;

      try {
        devices = await navigator.usb.getDevices();
      } catch (err) {
        setAutoPrint(false);
        return undefined;
      }

      if (devices.length > 0) {
        return devices[0];
      }

      try {
        return await navigator.usb.requestDevice({
          filters: [{ vendorId: 1208 }],
        });
      } catch (err) {
        setAutoPrint(false);
      }
    }

    return device;
  };

  const initPrinter = async () => {
    device = await getDevices();

    if (!device) {
      setAutoPrint(false);
      return;
    }

    try {
      if (!device.opened) await device.open();
      if (device.configuration === null) await device.selectConfiguration(1);
    } catch (err: any) {
      if (!err.message.includes('device state is in progress')) {
        console.error('Error initializing printer', err);

        Swal.fire({
          title: 'Impressora indisponível',
          text: 'A impressora parece estar sendo usada em outra aba/janela do navegador. Não foi possível iniciar. Utilize somente uma aba por vez com a impressora.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }
    }

    endpointNumber = 1;
  };

  setInterval(async () => {
    if (printQueue.length === 0) return;

    const printerInterface = device?.configuration?.interfaces?.[0]?.interfaceNumber ?? 0;

    const content = printQueue[0];

    if (!device) {
      Swal.fire({
        title: 'Impressora indisponível',
        text: 'A impressora parece estar sendo usada em outra aba/janela do navegador. Não foi possível iniciar. Utilize somente uma aba por vez com a impressora.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      printQueue.splice(0, 1);
      return;
    }

    try {
      await device.claimInterface(printerInterface);
    } catch (e: unknown) {
      return;
    }

    try {
      if (device) await device.transferOut(endpointNumber, content);
    } catch (e: unknown) {
      if (e instanceof Error && e.message.includes('The specified endpoint is not part of a claimed and selected alternate interface')) {
        if (endpointNumber < 15) {
          endpointNumber += 1;
          return;
        }
      }

      endpointNumber = 1;
      Swal.fire({
        title: 'Impressora indisponível.',
        text: `Não foi possível realizar a impressão. Verifique a situação da impressora (ou reinicie ela) e tente novamente. ${e instanceof Error ? e.message : ''}`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'Tentar novamente',
        showCancelButton: true,
        cancelButtonText: 'Fechar',
      });
    }

    if (device) {
      try {
        await device.releaseInterface(printerInterface);

        printQueue.splice(0, 1);
      } catch (err) {
        console.error('Error releasing interface', err);
      }
    }
  }, 1000);

  const sendMessageToPrinter = async (content: ArrayBufferLike) => {
    if (window?.api) {
      window.api.request('toMain', content);
    } else {
      printQueue.push(content);
    }
  };

  const startPrint = async (content: ArrayBufferLike) => sendMessageToPrinter(content);

  useEffect(() => {
    try {
      if (localStorage.getItem('autoPrintEnabled') === 'true') {
        setAutoPrint(true);
      }
    } catch (e: unknown) {
      //
    }
  }, []);

  useEffect(() => {
    if (autoPrint === true) {
      if (!window?.api) initPrinter();

      const snapshot = db
        .collectionGroup('billItems')
        .where('createdAt', '>=', new Date())
        .where('venueId', '==', values?.usingVenue)
        .orderBy('createdAt', 'asc')
        .withConverter(firestoreComandaItemsConverter)
        .onSnapshot((newItems) => {
          printNewItems({ newItems, startPrint });
        });

      return () => {
        snapshot();
      };
    }

    return () => {
      //
    };
  }, [autoPrint]);

  const value = useMemo<PrinterContextValues>(() => ({
    printerType,
    setPrinterType,
    startPrint,
    autoPrint,
    setAutoPrint: (isEnabled: boolean) => {
      localStorage.setItem('autoPrintEnabled', isEnabled ? 'true' : 'false');
      setAutoPrint(isEnabled);
    },
  }), [printerType, autoPrint]);

  return (
    <PrinterContext.Provider value={value}>{children}</PrinterContext.Provider>
  );
}

export default PrinterContext;

export { PrinterProvider };
