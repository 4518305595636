import currency from 'currency.js';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl, FormLabel, Select, Spinner, Text, VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  loadAddresses,
  openAddAddressModal,
  resetDeliveryAddress,
  selectDeliveryAddress,
  selectFreightAmount,
  selectFreightError,
  selectIsCalculatingFreight,
  selectLoadingAddresses,
  selectUserAddresses,
  setDeliveryAddress,
} from '../../redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

export default function AddressSelector() {
  const dispatch = useAppDispatch();
  const addresses = useAppSelector(selectUserAddresses);
  const loadingAddresses = useAppSelector(selectLoadingAddresses);
  const deliveryAddress = useAppSelector(selectDeliveryAddress);
  const calculatingFreight = useAppSelector(selectIsCalculatingFreight);
  const freightAmount = useAppSelector(selectFreightAmount);
  const freightError = useAppSelector(selectFreightError);

  const changeSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (event.target.value === 'new') {
      dispatch(openAddAddressModal());
    } else {
      const address = addresses.find((a) => a.id === event.target.value);
      if (address) dispatch(setDeliveryAddress(address));
    }
  };

  useEffect(() => {
    dispatch(loadAddresses());
  }, []);

  return (
    <>
      {calculatingFreight !== 'succeeded' && (
        <FormControl>
          <FormLabel htmlFor="address">Selecione o endereço de entrega:</FormLabel>
          <Select disabled={calculatingFreight === 'pending'} onChange={changeSelect} value={deliveryAddress?.id} id="address" placeholder="Escolha um endereço">
            {loadingAddresses === 'pending' && <option>Carregando...</option>}
            {addresses.map((address) => (
              <option value={address.id} key={`address.${address.id}`}>
                {address.data.street}
                ,
                {' '}
                {address.data.number}
                {' '}
                -
                {' '}
                {address.data.cityName}
              </option>
            ))}
            <option value="new">Adicionar novo endereço...</option>
          </Select>
        </FormControl>
      )}

      {(calculatingFreight === 'succeeded' || calculatingFreight === 'pending') && (
        <VStack flex={1} alignItems="start" flexDirection="column" borderRadius="md">
          <Flex width="100%" alignItems="center">
            <Text fontWeight="black" mr="auto">Taxa de entrega:</Text>

            {calculatingFreight === 'succeeded' && (
            <Text fontWeight="black">
              {currency(freightAmount, { symbol: 'R$', decimal: ',', separator: '.' }).format()}
            </Text>
            )}

            {calculatingFreight === 'pending' && (<Spinner size="xs" />)}
          </Flex>
          {calculatingFreight === 'succeeded' && (
            <Flex width="100%" alignItems="center">
              <Text fontSize="xs" textAlign="left">
                {[deliveryAddress?.data.street, deliveryAddress?.data.number, deliveryAddress?.data.complement, deliveryAddress?.data.district, deliveryAddress?.data.cityName].filter((i) => !!i).join(', ')}
              </Text>
              <Button size="sm" variant="outline" ml={3} width="120px" colorScheme="blue" onClick={() => dispatch(resetDeliveryAddress())}>Alterar</Button>
            </Flex>
          )}
        </VStack>
      )}

      {calculatingFreight === 'failed' && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{freightError}</AlertDescription>
        </Alert>
      )}
    </>
  );
}
