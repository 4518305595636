import React, { lazy, useContext, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ComandaContextOutlet from 'components/ComandaContextOutlet';
import { PrinterProvider } from 'contexts/printer';
import SessionContext from 'contexts/session';

const ReadCodeScreen = lazy(() => import('screens/ReadCode'));
const BillLookupScreen = lazy(() => import('screens/BillLookup'));
const TablesScreen = lazy(() => import('screens/Tables'));
const ManageBillScreen = lazy(() => import('screens/ManageBill'));
const ManageBillPaymentScreen = lazy(() => import('screens/ManageBillPayment'));
const ManageCategoriesScreen = lazy(() => import('screens/ManageCategories'));
const ManageProductsScreen = lazy(() => import('screens/ManageProducts'));
const ManageSettingsScreen = lazy(() => import('screens/ManageSettings'));
const ManagePaymentMethodsScreen = lazy(() => import('screens/ManagePaymentMethods'));
const KitchenDashboardScreen = lazy(() => import('screens/KitchenDashboard'));
const ManagerReportItems = lazy(() => import('screens/ManagerReportItems'));
const ManagerReportsBills = lazy(() => import('screens/ManagerReportsBills'));
const ManagerReportsScreen = lazy(() => import('screens/ManagerReports'));
const VenuesScreen = lazy(() => import('screens/Venues'));

interface ManagerParams {
  venueId: string;
  [key: string]: string;
}

function VenueRoutes() {
  const { setUsingVenue } = useContext(SessionContext);
  const params = useParams<ManagerParams>();

  useEffect(() => {
    if (params.venueId) {
      setUsingVenue(params.venueId);
    }
  }, [params.venueId]);

  return (
    <PrinterProvider values={{ usingVenue: params.venueId }}>
      <Routes>
        <Route
          path="read"
          element={(
            <ReadCodeScreen type="manager" headerStyles={{ backgroundColor: 'black' }} backLink={`/manager/${params.venueId}`} />
        )}
        />
        <Route path="p/:billId" element={<ComandaContextOutlet type="p" />}>
          <Route index element={<ManageBillScreen />} />
          <Route path="payments" element={<ManageBillPaymentScreen />} />
        </Route>
        <Route path="h/:billId" element={<ComandaContextOutlet type="h" />}>
          <Route index element={<BillLookupScreen type="h" />} />
        </Route>
        <Route path="reports">
          <Route path="soldItems" element={<ManagerReportItems />} />
          <Route path="bills" element={<ManagerReportsBills />} />
          <Route index element={<ManagerReportsScreen />} />
        </Route>
        <Route path="categories" element={<ManageCategoriesScreen />} />
        <Route path="monitor/kitchen" element={<KitchenDashboardScreen />} />
        <Route path="monitor/service" element={<KitchenDashboardScreen waiterView />} />
        <Route path="products" element={<ManageProductsScreen />} />
        <Route path="paymentMethods" element={<ManagePaymentMethodsScreen />} />
        <Route path="settings" element={<ManageSettingsScreen />} />
        <Route index element={<TablesScreen />} />
      </Routes>
    </PrinterProvider>
  );
}

function Manager() {
  return (
    <Routes>
      <Route index element={<VenuesScreen />} />
      <Route path=":venueId/*" element={<VenueRoutes />} />
    </Routes>
  );
}

export default Manager;
