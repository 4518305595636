import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ComandaContext from 'contexts/comanda';
import currency from 'currency.js';
import { db } from 'firebaseApp';
import { FaShoppingCart } from 'react-icons/fa';
import firebase from 'firebase';
import FirebaseImage from 'components/FirebaseImage';
import HeadingScreen from 'components/HeadingScreen';
import { Helmet } from 'react-helmet';
import ItemSetup from 'screens/ItemSetup';
import ReviewOrder from 'components/ReviewOrder';
import { useNavigate } from 'react-router-dom';
import VenueHeader from 'components/VenueHeader';

export const firestoreProductCategoryUseConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.ProductCategory> = {
    toFirestore(venue: Pagcomanda.ProductCategory) {
      return venue;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.ProductCategory {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.ProductCategory;
    },
  };

export const firestoreProductUseConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Product> = {
    toFirestore(venue: Pagcomanda.Product) {
      return venue;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Product {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Product;
    },
  };

export default function VenueMenu({ vId }: {vId?: string}) {
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  const productsContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const {
    billId,
    billType,
    venue,
    venueId = vId,
    isReady,
    pendingOrder,
  } = useContext(ComandaContext);
  const [
    productsCategories,
    setProductsCategories,
  ] = useState<firebase.firestore.QueryDocumentSnapshot<Pagcomanda.ProductCategory>[] | []>([]);
  const [
    productsAvailable,
    setProductsAvailable,
  ] = useState<firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Product>[] | []>([]);
  const [usingIndex, setUsingIndex] = useState(0);
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Product>>();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isReady && !venueId) return;

    db.collection('venues')
      .doc(venueId)
      .collection('productsCategories')
      .withConverter(firestoreProductCategoryUseConverter)
      .where('isPublished', '==', true)
      .orderBy('parsedName', 'asc')
      .get()
      .then((cats: firebase.firestore.QuerySnapshot<Pagcomanda.ProductCategory>) => {
        if (cats.empty) {
          setProductsCategories([]);
        } else {
          setProductsCategories(cats.docs);
        }
      });

    db.collection('venues')
      .doc(venueId)
      .collection('products')
      .withConverter(firestoreProductUseConverter)
      .where('isPublished', '==', true)
      .orderBy('parsedName', 'asc')
      .get()
      .then((prods: firebase.firestore.QuerySnapshot<Pagcomanda.Product>) => {
        if (prods.empty) {
          setProductsAvailable([]);
        } else {
          const publishedDocs: firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Product>[] = [];
          prods.docs.forEach((doc) => {
            if (!doc.data()?.hideOnMenu) {
              publishedDocs.push(doc);
            }
          });
          setProductsAvailable(publishedDocs);
        }
      });
  }, [isReady, venue, venueId]);

  useEffect(() => {
    const tolerance = 120;

    if (!productsContainerRef.current) {
      return function unmount() {
        return null;
      };
    }

    const onScroll = () => {
      if (productsContainerRef.current && itemsRef.current) {
        const containerRef = productsContainerRef.current;
        const itemsArr = itemsRef.current;
        setScrollTop(() => {
          const newScroll = containerRef.scrollTop + containerRef.offsetTop;

          itemsArr.forEach((item, index) => {
            if (itemsArr.length > index + 1) {
              const nextItem = itemsArr[index + 1];
              if ((nextItem?.offsetTop ?? 0) < (newScroll ?? 0) + tolerance) {
                return;
              }
            }

            if ((item?.offsetTop ?? 0) < (newScroll ?? 0) + tolerance) {
              setUsingIndex(() => {
                if (
                  containerRef.offsetHeight
                  + containerRef.scrollTop
                  === containerRef.scrollHeight) {
                  return itemsArr.length - 1;
                }

                const newIndex = parseInt(item?.dataset?.index ?? '0', 10);
                return newIndex;
              });
            }
          });

          return newScroll;
        });
      }
    };
    productsContainerRef.current.addEventListener('scroll', onScroll);

    return () => {
      if (productsContainerRef.current) {
        productsContainerRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollTop, productsContainerRef.current, itemsRef.current]);

  return (
    <HeadingScreen
      title={`Cardápio para ${venue?.data()?.CommercialName}`}
      link={billId && billType ? `/${billType === 'delivery' ? 'p' : billType}/${billId}` : undefined}
    >
      <Helmet>
        <title>{`Cardápio para ${venue?.data()?.CommercialName}`}</title>
      </Helmet>
      <Container display="flex" flexDirection="column" p={0} height="100%">
        <VenueHeader hideDivider venue={venue} />
        <HStack
          isInline
          spacing={2}
          paddingY={3}
          width="100%"
          overflowX="scroll"
          overflowY="hidden"
          wrap="nowrap"
          shouldWrapChildren={false}
          display="grid"
          gridAutoFlow="column"
          gridAutoColumns="max-content"
          marginBottom={3}
          px={3}
        >
          {productsCategories
            && productsCategories.sort(
              (a, b) => (a.data().order > b.data().order ? 1 : -1),
            ).map((category, index) => {
              const countProducts = productsAvailable
                && productsAvailable.map(
                  (product) => product.data().category?.id === category.id,
                ).filter((item) => item === true);

              if (countProducts.length <= 0) return null;

              return (
                <Button
                  key={`category_button_${category.get('description')}`}
                  size="xs"
                  colorScheme={index === usingIndex ? 'redComanda' : 'blackSolid'}
                  onClick={() => {
                    const itemAnchor = itemsRef.current[index];
                    if (itemAnchor !== null) {
                      itemAnchor.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }}
                >
                  {category.data().description}
                </Button>
              );
            })}
        </HStack>

        <Flex flexDirection="column" height="100%" overflow="auto" ref={productsContainerRef}>
          {productsCategories
            && productsCategories.sort(
              (a, b) => (a.data().order > b.data().order ? 1 : -1),
            ).map((category, index) => {
              const countProducts = productsAvailable
                && productsAvailable.map(
                  (product) => product.data().category?.id === category.id,
                ).filter((item) => item === true);

              if (countProducts.length <= 0) return null;

              return (
                <Box
                  id={category.id}
                  key={`menu-category-${category.id}`}
                  data-index={index}
                  padding={0}
                  my={1}
                  ref={(el) => { itemsRef.current[index] = el; }}
                >
                  <Box
                    backgroundColor="redComanda.500"
                    color="white"
                    _hover={{ backgroundColor: '#000' }}
                    paddingX={3}
                    paddingY={2}
                  >
                    <strong>{category.get('description')}</strong>
                  </Box>

                  <VStack divider={<Divider />} spacing={0}>
                    {productsAvailable
                  && productsAvailable.map((product) => {
                    if (product.data().category?.id === category.id) {
                      let stackFlexDirection: FlexProps['direction'] = 'row';

                      if (product.data().imagePosition === 'RIGHT') {
                        stackFlexDirection = 'row-reverse';
                      }

                      if (product.data().imagePosition === 'TOP') {
                        stackFlexDirection = 'column';
                      }

                      const isPromotion = (product.get('originalPrice') ?? 0) > product.data().price;

                      return (
                        <Stack
                          onClick={() => setSelectedItem(product)}
                          cursor="pointer"
                          isInline={product.data().imagePosition !== 'TOP'}
                          key={`item-${category.id}-${product.id}`}
                          display="flex"
                          alignItems="center"
                          spacing={1}
                          flexDirection={stackFlexDirection}
                          width="100%"
                        >
                          {product.data().image && (
                            <Flex
                              minWidth={150}
                              minHeight={150}
                              width={
                                product.data().imagePosition === 'TOP' ? '100%' : undefined
                              }
                              alignItems="center"
                              justifyContent="center"
                            >
                              <FirebaseImage
                                storageUri={product.data().image}
                                width={
                                  product.data().imagePosition === 'TOP'
                                    ? '100%'
                                    : 150
                                }
                                maxHeight={150}
                                objectFit={
                                  product.data().imagePosition === 'TOP'
                                    ? 'cover'
                                    : 'contain'
                                }
                                objectPosition="50% 50%"
                              />
                            </Flex>
                          )}
                          <HStack
                            marginTop={0}
                            padding={3}
                            spacing={3}
                            width="100%"
                            flexDirection={
                              product.data().imagePosition === 'RIGHT'
                                ? 'row-reverse'
                                : 'row'
                            }
                          >
                            <Box
                              width="100%"
                              marginLeft={
                                product.data().imagePosition === 'RIGHT' ? 3 : undefined
                              }
                            >
                              {product.data().description && (
                              <Text fontSize="md" fontWeight="bold">
                                {product.data().description}
                              </Text>
                              )}

                              {product.data().about && (
                              <Text
                                fontSize="xs"
                              >
                                {product.data().about}
                              </Text>
                              )}

                              <Text fontSize="sm">
                                {isPromotion
                                  && (
                                  <Text as="span" color="blackAlpha.600" textDecoration="line-through" aria-label="Preço antigo" mr={1}>
                                    {currency(product.get('originalPrice'), { symbol: 'R$', decimal: ',', separator: '.' }).format()}
                                  </Text>
                                  )}
                                {currency(product.data().price, { symbol: 'R$', decimal: ',', separator: '.' }).format()}
                              </Text>
                            </Box>
                          </HStack>
                        </Stack>
                      );
                    } return null;
                  })}
                  </VStack>
                </Box>
              );
            })}
        </Flex>
        {venue && venue.get('AllowConsumerOrder') && (
          <HStack
            backgroundColor="blackSolid.500"
            color="white"
            spacing={5}
            padding={5}
            marginTop="auto"
            shadow="sm"
            display="flex"
            alignItems="center"
            rounded="sm"
            paddingBottom={10}
            roundedTop={10}
            onClick={() => (pendingOrder && pendingOrder.length > 0 ? onOpen() : null)}
          >
            <Icon w={6} h={6} as={FaShoppingCart} />
            {(!pendingOrder || pendingOrder.length === 0) && (
              <Text>Carrinho vazio</Text>
            )}
            {pendingOrder && pendingOrder.length > 0 && (
              <>
                <Text>
                  {pendingOrder && pendingOrder.length}
                  {' '}
                  {pendingOrder && pendingOrder.length === 1 ? 'item' : 'itens'}
                  {' '}
                  no carrinho
                </Text>
                <Button
                  size="sm"
                  colorScheme="redComanda"
                  style={{ marginLeft: 'auto' }}
                  onClick={onOpen}
                >
                  Ver pedido
                </Button>
              </>
            )}
          </HStack>
        )}
      </Container>
      <ItemSetup
        product={selectedItem}
        isOpen={selectedItem !== undefined}
        onClose={() => setSelectedItem(undefined)}
      />
      <ReviewOrder
        isOpen={isOpen}
        onClose={(data) => {
          onClose();
          if (data.Success) {
            navigate(`/${data.BillType}/${data.BillId}`);
          }
        }}
      />
    </HeadingScreen>
  );
}

VenueMenu.defaultProps = {
  vId: undefined,
};
