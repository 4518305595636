import { analytics, firebaseAuth } from 'firebaseApp';
import { BsArrowLeft, BsGoogle } from 'react-icons/bs';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import firebase from 'firebase';

export interface SignInModalProps {
  continueUrl?: string;
  isOpen: boolean;
  onClose?: (user?: firebase.auth.UserCredential) => void;
}

interface SubmitAuthProps extends FieldValues {
  email: string;
  password: string;
}

export default function SignInModal({
  continueUrl,
  isOpen = false,
  onClose,
}: SignInModalProps) {
  analytics.setCurrentScreen('Login screen');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SubmitAuthProps>();
  const { isOpen: showPassword, onToggle: togglePassword } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [showSignInForm, setShowSignInForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const signInWithGoogle = () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    setIsLoading(true);
    return firebaseAuth
      .signInWithPopup(googleProvider)
      .then((user) => {
        analytics.logEvent('signin');
        if (continueUrl) {
          navigate(continueUrl);
          if (typeof onClose === 'function') onClose(user);
        } else if (typeof onClose === 'function') onClose(user);
      })
      .catch((err) => {
        setIsLoading(false);
        analytics.logEvent('signin_error', {
          errorMessage: err.message,
          errorCode: err.code,
        });
        toast({
          title: 'Erro ao autenticar',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const onSubmit: SubmitHandler<SubmitAuthProps> = (data) => {
    setIsLoading(true);
    return firebaseAuth
      .signInWithEmailAndPassword(data.email, data.password)
      .then((user) => {
        analytics.logEvent('signin');
        if (continueUrl) {
          navigate(continueUrl);
          if (typeof onClose === 'function') onClose(user);
        } else if (typeof onClose === 'function') onClose(user);
      })
      .catch((err) => {
        setIsLoading(false);
        analytics.logEvent('signin_error', {
          errorMessage: err.message,
          errorCode: err.code,
        });
        toast({
          title: 'Erro ao autenticar',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setError('password', { type: 'validate', message: err.message });
      });
  };

  const onModalClose = () => {
    setShowSignInForm(false);
    if (typeof onClose === 'function') onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        {showSignInForm && (
        <ModalHeader>
          <IconButton
            icon={<BsArrowLeft size="30px" />}
            mr={3}
            mb={0}
            onClick={() => setShowSignInForm(false)}
            aria-label="Voltar"
            variant="ghost"
            size="md"
          />
          Entrar com e-mail e senha
        </ModalHeader>
        )}

        {!showSignInForm && (
        <>
          <ModalHeader>Acesse sua conta</ModalHeader>
          <ModalCloseButton />
        </>
        )}

        <ModalBody>
          {showSignInForm && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.email} marginBottom={4}>
              <FormLabel htmlFor="emailInput">E-mail</FormLabel>
              <Input
                autoFocus
                id="emailInput"
                placeholder="email@exemplo.com.br"
                autoComplete="email"
                borderColor={useColorModeValue('gray.400', 'facebook.50')}
                backgroundColor="whiteAlpha.400"
                errorBorderColor="red.300"
                {...register('email', { required: true })}
              />
              <FormErrorMessage color="red.300">
                {errors
                  && errors.email
                  && errors.email.type === 'required'
                  && 'Preencha o e-mail'}
                {errors
                  && errors.email
                  && errors.email.type === 'validate'
                  && 'O e-mail está inválido ou não está cadastrado'}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password} marginBottom={4}>
              <FormLabel htmlFor="passwordInput">Senha</FormLabel>
              <InputGroup size="md">
                <Input
                  id="passwordInput"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="password"
                  borderColor={useColorModeValue('gray.400', 'facebook.50')}
                  backgroundColor="whiteAlpha.400"
                  errorBorderColor="red.300"
                  {...register('password', { required: true, minLength: 6 })}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Mostrar senha"
                    variant="ghost"
                    colorScheme="gray"
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    h="1.75rem"
                    size="sm"
                    onClick={togglePassword}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage color="red.300">
                {errors
                  && errors.password
                  && errors.password.type === 'required'
                  && 'A senha deve ter pelo menos 6 caracteres'}
                {errors
                  && errors.password
                  && errors.password.type === 'validate'
                  && 'Verifique se usuário e senha estão corretos'}
              </FormErrorMessage>
            </FormControl>

            <Flex direction="column">
              <Button
                variant="link"
                colorScheme="blackSolid"
                as={Link}
                size="sm"
                marginRight="auto"
                marginBottom={5}
                to="/forgot"
                onClick={() => {
                  analytics.logEvent('password_forgot', {
                    fromScreen: 'login',
                  });
                }}
              >
                Esqueceu a senha?
              </Button>

              <Button
                variant="solid"
                colorScheme="blackSolid"
                isFullWidth
                type="submit"
                size="lg"
                isLoading={isLoading}
              >
                Entrar
              </Button>

              <Button
                size="sm"
                variant="link"
                colorScheme="blackSolid"
                as={Link}
                to={
                  continueUrl
                    ? `/signup?continueUrl=${encodeURIComponent(continueUrl)}`
                    : '/signup'
                }
                marginTop={8}
                marginBottom={5}
                isFullWidth
                onClick={() => {
                  analytics.logEvent('signup_intent', {
                    fromScreen: 'login',
                  });
                }}
              >
                Cadastre-se
              </Button>
            </Flex>
          </form>
          )}

          {!showSignInForm && (
          <VStack spacing={3} my={3}>
            <Button
              variant="solid"
              colorScheme="red"
              isFullWidth
              size="lg"
              leftIcon={<BsGoogle />}
              mt={5}
              onClick={signInWithGoogle}
            >
              Entrar com Google
            </Button>
            <Button
              size="lg"
              variant="outline"
              colorScheme="blackSolid"
              isFullWidth
              onClick={() => setShowSignInForm(true)}
            >
              Usar e-mail e senha
            </Button>
          </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

SignInModal.defaultProps = {
  continueUrl: undefined,
  onClose: undefined,
};
