/* eslint-disable */
import React, {
  useState, useEffect, useRef, ReactChild,
} from 'react';
import firebase from 'firebase';
import { functions, db, firestore } from 'firebaseApp';
import currency from "currency.js";
import { firestoreVenueConverter } from 'contexts/session';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  addItemToPendingOrder as addItemToPendingOrderAction, 
  removeItemFromPendingOrder as removeItemFromPendingOrderAction, 
  cleanPendingOrder as cleanPendingOrderAction,
  selectPendingOrders, 
  setDeliveryData
} from '../redux/slices/bills.slice';
import { resetDeliveryAddress, selectDeliveryAddress, selectFreightAmount } from '../redux/slices/user.slice';
interface ComandaContextProps {
  isReady: boolean;
  billType?: Pagcomanda.BillType;
  billId?: Pagcomanda.BillId;
  comanda?: Pagcomanda.Comanda | null;
  comandaRef?: firebase.firestore.DocumentReference<Pagcomanda.Comanda>;
  comandaItems?: firebase.firestore.QuerySnapshot<Pagcomanda.ComandaItem>;
  venue?: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>;
  venueId?: string;
  pendingOrder?: Array<Pagcomanda.ComandaItem> | undefined;
  totalComanda: currency;
  totalPaid: currency;
  isDisposable?: boolean;
  metadata?: Pagcomanda.ComandaMetadata;
  cleanPendingOrder: () => void;
  addItemToPendingOrder: (item: Pagcomanda.ComandaItem) => void;
  removeItemFromPendingOrder: (index: number) => void;
  submitOrder: SubmitOrderFunction;
}

type SubmitOrderFunction = (items?: Array<Pagcomanda.ComandaItem>, metadata?: {tableNumber?: string; metadata?: Pagcomanda.ComandaMetadata } | undefined) => Promise<AddItemToComandaResult>;

interface ComandaProviderProps {
  children: ReactChild;
  value?: {
    billType?: Pagcomanda.BillType;
    billId?: Pagcomanda.BillId;
    venueId?: Pagcomanda.BillId;
  }
}

export interface AddItemToComanda {
  Success?: boolean;
  BillType?: Pagcomanda.BillType;
  BillId?: Pagcomanda.BillId;
  NewBillId?: string;
}

interface AddItemToComandaResult extends firebase.functions.HttpsCallableResult {
  data: AddItemToComanda;
}

const ComandaContext = React.createContext<ComandaContextProps>({
  isReady: false,
  totalComanda: currency(0),
  totalPaid: currency(0),
  cleanPendingOrder: () => null,
  addItemToPendingOrder: (item: Pagcomanda.ComandaItem) => null,
  removeItemFromPendingOrder: (index: number) => null,
  submitOrder: (items: Array<Pagcomanda.ComandaItem> | undefined, metadata?: {tableNumber?: string;} | undefined) => new Promise<AddItemToComandaResult>((resolve) => resolve({} as AddItemToComandaResult)),
});

export const firestoreComandaUseConverter: firebase.firestore.FirestoreDataConverter<Pagcomanda.Comanda> = {
  toFirestore(venue: Pagcomanda.Comanda) {
    return venue;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Pagcomanda.Comanda {
    const data = snapshot.data(options);

    if (!data) {
      throw new Error();
    }

    return data as Pagcomanda.Comanda;
  },
};

export const firestoreComandaItemUseConverter: firebase.firestore.FirestoreDataConverter<Pagcomanda.ComandaItem> = {
  toFirestore(venue: Pagcomanda.ComandaItem) {
    return venue;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Pagcomanda.ComandaItem {
    const data = snapshot.data(options);

    if (!data) {
      throw new Error();
    }

    return data as Pagcomanda.ComandaItem;
  },
};

function ComandaProvider({ children, value }: ComandaProviderProps) {
  const dbSnapRef = useRef<() => void>();
  const dbItemsRef = useRef<() => void>();
  const {billType, billId, venueId} = value ?? {};

  const dispatch = useAppDispatch();

  const [isReady, setIsReady] = useState<boolean>(false);
  const [comanda, setComanda] = useState<Pagcomanda.Comanda | null>();
  const [comandaRef, setComandaRef] = useState<firebase.firestore.DocumentReference<Pagcomanda.Comanda>>();
  const [comandaItems, setComandaItems] = useState<firebase.firestore.QuerySnapshot<Pagcomanda.ComandaItem>>();
  const [venue, setVenue] = useState<firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>>();

  const pendingOrdersFromRedux = useAppSelector(selectPendingOrders);
  const freightAmount = useAppSelector(selectFreightAmount);
  const deliveryAddress = useAppSelector(selectDeliveryAddress);

  useEffect(() => {
    setIsReady(false);
    if (typeof dbSnapRef?.current === 'function') {
      dbSnapRef.current();
      dbSnapRef.current = undefined;
    }
    if (typeof dbItemsRef?.current === 'function') {
      dbItemsRef.current();
      dbItemsRef.current = undefined;
    }
    console.log("Unmount comanda.");
  }, []);

  useEffect(() => {
    if (!venueId) return;

    db
    .collection('venues')
    .doc(venueId)
    .withConverter(firestoreVenueConverter)
    .get()
    .then((v: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>) => setVenue(v));
  }, [venueId]);

  useEffect(() => {
    if (dbSnapRef.current || dbItemsRef.current) return;

    if (!billId) return;

    console.log("Mount comanda.", billType, billId);

    const ref = db
      .collection(billType !== 'p' ? 'bills' : 'bill-type-p')
      .withConverter(firestoreComandaUseConverter)
      .doc(billId);

    dbSnapRef.current = ref.onSnapshot((comandaDoc) => {
      if (!comandaDoc.exists) {
        setComanda(null);
        return;
      };
      const doc = comandaDoc.data() as Pagcomanda.Comanda;
      setComanda(doc);
      dispatch(setDeliveryData({
        billId: comandaDoc.id,
        metadata: (doc?.metadata ?? {}) as Pagcomanda.ComandaMetadata
      }))
      setComandaRef(ref);
      if (doc.venue) {
        doc.venue.get().then((v: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>) => setVenue(v));
      }
    });

    dbItemsRef.current = db
      .collection(billType !== 'p' ? 'bills' : 'bill-type-p')
      .doc(billId)
      .collection('billItems')
      .withConverter(firestoreComandaItemUseConverter)
      .orderBy('createdAt', 'asc')
      .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Pagcomanda.ComandaItem>) => setComandaItems(snapshot));
  }, [billType, billId]);

  useEffect(() => {
    if (
      comanda !== undefined
      && comandaItems !== undefined
      && venue !== undefined
    ) {
      setIsReady(true);
    }
  }, [comanda, comandaItems, venue]);

  useEffect(() => () => {
    if (typeof dbSnapRef?.current === 'function') dbSnapRef.current();
    if (typeof dbItemsRef?.current === 'function') dbItemsRef.current();
  }, []);

  let totalComanda = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });
  let totalPaid = currency(0, { symbol: 'R$', decimal: ',', separator: '.' });

  if (comandaItems && comandaItems?.size > 0) {
    comandaItems.docs.forEach((doc) => {
      const item = doc.data();
      const price = currency(item.price);
      if (item.price >= 0 && !item?.isCanceled) {
          totalComanda = totalComanda.add(price.add(item?.additionalPrice ?? 0).multiply(item.qty));
      } else if (!item?.isCanceled) {
        totalPaid = totalPaid.add(price.add(item?.additionalPrice ?? 0).multiply(item.qty));
      }
    });
  }

  const addItemToPendingOrder = (item: Pagcomanda.ComandaItem) => {
    dispatch(addItemToPendingOrderAction({
      orderId: billId,
      item,
    }));
  };

  const removeItemFromPendingOrder = (index: number) => {
    dispatch(removeItemFromPendingOrderAction({
      orderId: billId,
      itemIndex: index,
    }));
  };

  const cleanPendingOrder = () => {
    dispatch(resetDeliveryAddress());
    dispatch(cleanPendingOrderAction({ orderId: billId }));
  };

  const submitOrder: SubmitOrderFunction = (items, data) => {
    const Items = [ ...(items || pendingOrdersFromRedux?.[billId || 'default'] || []) ];
    const {tableNumber, metadata} = data ?? {};

    if (tableNumber) {
      Items?.forEach((item, key) => {
        Items[key]['tableNumber'] = tableNumber;
      });
    }

    const finalMetadata = {...metadata};

    const fnAddItemToComanda = functions.httpsCallable('addItemToComanda');
    const formattedAddress = [deliveryAddress?.data.street, deliveryAddress?.data.number, deliveryAddress?.data.complement, deliveryAddress?.data.district, deliveryAddress?.data.cityName].filter((i) => !!i).join(", ");

    if (freightAmount > 0 && metadata?.['orderType']?.value === 'delivery') {
      Items.push({
        productId: 'CALCULATED_DELIVERY',
        type: 'purchase',
        createdAt: firestore.FieldValue.serverTimestamp(),
        description: 'Taxa de entrega',
        isServed: false,
        price: freightAmount,
        unity: 'UN',
        hasPreparing: false,
        hasService: false,
        comments: formattedAddress,
        qty: 1,
      });

      finalMetadata['orderOrigin'] = {
        label: 'Origem do Pedido',
        value: 'webapp',
        description: 'SITE'
      };

      finalMetadata['customerName'] = {
        label: 'Nome do cliente',
        value: '',
        description: ''
      };

      
      finalMetadata['customerAddress'] = {
        label: 'Endereço do cliente',
        value: formattedAddress,
        description: formattedAddress,
      };
    }

    return fnAddItemToComanda({
      BillType: billType,
      BillId: billId,
      VenueId: venue?.id,
      Items,
      Metadata: finalMetadata,
    })
      .then<AddItemToComandaResult>((data) => {
        if (data?.data?.Success === true || data?.data?.newBillId) {
          cleanPendingOrder();
          return data;
        }
        throw new Error(data?.data?.error?.message ?? 'Erro desconhecido.');
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const finalValue: ComandaContextProps = {
    isReady,
    billType: comanda?.billType ?? billType,
    billId,
    comanda,
    comandaRef,
    comandaItems,
    venue,
    venueId: venue?.id,
    pendingOrder: pendingOrdersFromRedux?.[billId || 'default'] || [],
    totalComanda,
    totalPaid,
    metadata: comanda?.metadata ?? {},
    cleanPendingOrder,
    addItemToPendingOrder,
    removeItemFromPendingOrder,
    submitOrder,
  };

  return (
    <ComandaContext.Provider value={finalValue}>{children}</ComandaContext.Provider>
  );
}

export default ComandaContext;

export { ComandaProvider };
