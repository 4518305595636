import React, { useState, useEffect } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';
import { storage } from '../firebaseApp';

interface FirebaseImageProps extends ImageProps {
  storageUri?: Pagcomanda.FileType;
}

export default function FirebaseImage(props: FirebaseImageProps) {
  const { storageUri } = props;
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    try {
      if (typeof storageUri === 'string') {
        const storageRef = storage.refFromURL(storageUri);
        storageRef.getDownloadURL().then((image) => {
          if (!image) {
            setImageUrl('/missing-image.png');
          } else {
            setImageUrl(image);
          }
        });
      }
    } catch (err) {
      setImageUrl('/missing-image.png');
    }
  }, [storageUri]);

  const finalProps = { ...props };

  delete finalProps.storageUri;

  if (!imageUrl) {
    return (
      <Image
        src="/loader.gif"
        {...finalProps}
      />
    );
  }

  return (
    <Image
      loading="lazy"
      fallbackSrc="/missing-image.png"
      src={imageUrl}
      {...finalProps}
    />
  );
}

FirebaseImage.defaultProps = {
  storageUri: undefined,
};
