import * as Sentry from '@sentry/react';
import * as serviceWorker from 'serviceWorkerRegistration';
import App from 'App';
import { BrowserTracing } from '@sentry/browser';
import { ColorModeScript } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistor, store } from './redux/store';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://8b9d9793680e426ab164b4c77f0ea828@o1167188.ingest.sentry.io/6258001',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Router>
    <ColorModeScript />
    <Helmet
      encodeSpecialCharacters
      titleTemplate="%s [pagcomanda]"
      defaultTitle="pagcomanda - O jeito mais fácil de pagar a conta"
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
);

interface EventTargetExtended extends EventTarget {
  state?: string;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (!event?.target) return;

        const target: EventTargetExtended = event?.target;

        if (target?.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
