import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const ReadCodeScreen = lazy(() => import('screens/ReadCode'));
const PrivacyPolicyScreen = lazy(() => import('screens/PrivacyPolicy'));
const TermsOfUseScreen = lazy(() => import('screens/TermsOfUse'));
const PublicRoutes = [
  <Route key="ReadCodeScreen" path="/read" element={<ReadCodeScreen />} />,
  <Route key="TermsOfUseScreen" path="/tou" element={<TermsOfUseScreen />} />,
  <Route key="PrivacyPolicyScreen" path="/privacy-policy" element={<PrivacyPolicyScreen />} />,
];

export default PublicRoutes;
