import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/remote-config';

import firebase from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyDjfvt05pKHnttJ5L7BAFir1gDEOczoAx4',
  authDomain: 'comandas-811c7.firebaseapp.com',
  databaseURL: 'https://comandas-811c7.firebaseio.com',
  projectId: 'comandas-811c7',
  storageBucket: 'comandas-811c7.appspot.com',
  messagingSenderId: '760566004836',
  appId: '1:760566004836:web:13719e65c3f13250',
  measurementId: 'G-RFZ5PC7M00',
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const firebaseAuth = firebaseApp.auth();
export const { firestore } = firebase;
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const remoteConfig = firebase.remoteConfig();

db.settings({
  ignoreUndefinedProperties: true,
  merge: true,
});

if (process.env.NODE_ENV !== 'production') {
  firebaseAuth.useEmulator('http://localhost:9099/');
  db.useEmulator('localhost', 8080);
  functions.useEmulator('localhost', 5001);
  storage.useEmulator('localhost', 9199);
  remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000;
}

remoteConfig.defaultConfig = {
  isPayWithCreditCardEnabled: false,
};

remoteConfig.fetchAndActivate().then((fetchResult) => {
  if (fetchResult) console.log('Remote Config updated the settings.');
});

// db.enablePersistence({ synchronizeTabs: true }).catch((err) => {
//   if (err.code === 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code === 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });
