import React from 'react';
import {
  Flex, Spinner, Text, Button,
} from '@chakra-ui/react';
import { useOnline } from 'rooks';

interface LoadingProps {
  description?: string;
}

export default function Loading({ description }: LoadingProps) {
  const isOnline = useOnline();

  return (
    <Flex
      height="100%"
      direction="column"
      flexGrow="1"
      justifyContent="center"
      alignItems="center"
      padding={10}
    >
      <Spinner size="xl" />
      {!isOnline && (
        <>
          <Text color="red.600" marginTop={10}>
            Parece que você não está conectado à internet. Verifique sua
            conexão.
          </Text>
          <Button
            onClick={window.location.reload}
            variant="solid"
            colorScheme="blackSolid"
          >
            Atualizar página
          </Button>
        </>
      )}

      {description && (
        <Text color="orange.600" marginTop={10}>
          {description}
        </Text>
      )}
    </Flex>
  );
}

Loading.defaultProps = {
  description: undefined,
};
