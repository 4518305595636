import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logoBlack from 'assets/logo-black.svg';
import logoWhite from 'assets/logo-white.svg';
import React from 'react';

interface NotFoundProps {
  hideLogo?: boolean;
  backLink?: string;
  title?: string;
  description?: string;
  onBackLinkClick?: () => void;
  backLinkText?: string;
}

export default function NotFound({
  hideLogo = false,
  backLink = '/',
  onBackLinkClick,
  backLinkText = 'Ir para a home',
  title = 'Página não encontrada',
  description = 'A página que você tentou acessar não foi encontrada.',
}: NotFoundProps) {
  const logo = useColorModeValue(logoBlack, logoWhite);
  const bgColor = useColorModeValue('white', 'black');

  return (
    <Flex
      bgColor={bgColor}
      maxWidth={900}
      margin="auto"
      height="100%"
      direction="column"
      textAlign="center"
      flexGrow="1"
      justifyContent="center"
      padding={10}
    >
      {!hideLogo && (
        <Image
          alt="pagcomanda"
          marginTop={10}
          src={logo}
          style={{ maxWidth: 200, marginLeft: 'auto', marginRight: 'auto' }}
        />
      )}
      <Heading size="md" marginTop={10}>
        {title}
      </Heading>
      <Text>
        {description}
      </Text>
      {!onBackLinkClick && (
      <Button
        marginTop={10}
        as={Link}
        to={backLink}
        size="lg"
        variant="solid"
        colorScheme="blackSolid"
        isFullWidth
      >
        {backLinkText}
      </Button>
      )}

      {onBackLinkClick && (
      <Button
        marginTop={10}
        onClick={onBackLinkClick}
        size="lg"
        variant="solid"
        colorScheme="blackSolid"
        isFullWidth
      >
        {backLinkText}
      </Button>
      )}
    </Flex>
  );
}

NotFound.defaultProps = {
  hideLogo: false,
  backLink: '/',
  title: 'Página não encontrada',
  backLinkText: 'Ir para a home',
  onBackLinkClick: undefined,
  description: 'A página que você tentou acessar não foi encontrada.',
};
