import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { logout } from './user.slice';
import type { RootState } from '../store';

interface BillsState {
  pendingOrders: {
    ['default']: Pagcomanda.ComandaItem[];
    [billId: string]: Pagcomanda.ComandaItem[];
  };
  metadata: {
    [billId: string]: Pagcomanda.ComandaMetadata;
  };
}

const initialState: BillsState = {
  pendingOrders: {
    default: [],
  },
  metadata: {},
};

export const billsSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    addItemToPendingOrder: (state, action: PayloadAction<{
      orderId?: string;
      item: Pagcomanda.ComandaItem;
    }>) => {
      const { orderId, item } = action.payload;
      const orderKey = orderId || 'default';
      const newItems = [...(state.pendingOrders?.[orderKey] || [])];
      newItems.push(item);
      return {
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          [orderKey]: newItems,
        },
      };
    },

    removeItemFromPendingOrder: (state, action: PayloadAction<{
      orderId?: string;
      itemIndex: number;
    }>) => {
      const { orderId, itemIndex } = action.payload;
      const orderKey = orderId || 'default';
      const existingItems = [...(state.pendingOrders?.[orderKey] || [])];
      existingItems.splice(itemIndex, 1);

      return {
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          [orderKey]: existingItems,
        },
      };
    },

    cleanPendingOrder: (state, action: PayloadAction<{
      orderId?: string;
    }>) => {
      const { orderId } = action.payload;
      const orderKey = orderId || 'default';

      if (orderKey === 'default') {
        return {
          ...state,
          pendingOrders: {
            ...state.pendingOrders,
            default: [],
          },
        };
      }

      const pendingOrders = { ...state.pendingOrders };
      delete pendingOrders[orderKey];

      return {
        ...state,
        pendingOrders,
      };
    },

    setDeliveryData: (state, action: PayloadAction<{
        billId: string;
        metadata: Pagcomanda.ComandaMetadata;
    }>) => {
      const metadatas = { ...state.metadata };
      const { billId, metadata } = action.payload;

      metadatas[billId] = metadata;

      return {
        ...state,
        metadata: metadatas,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, (state) => ({ ...state, ...initialState }));
  },
});

export const {
  setDeliveryData,
  addItemToPendingOrder,
  removeItemFromPendingOrder,
  cleanPendingOrder,
} = billsSlice.actions;

export const selectBills = (state: RootState) => state.bills.metadata;
export const selectBillById = (state: RootState, billId: string) => state.bills.metadata[billId];

export const selectPendingOrders = (state: RootState) => state.bills.pendingOrders;

export default billsSlice.reducer;
