import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ComandaContext, { AddItemToComanda } from 'contexts/comanda';
import { GiKnifeFork, GiShoppingBag } from 'react-icons/gi';
import { MdArrowForward, MdOutlineSend, MdPedalBike } from 'react-icons/md';
import React, { useContext, useEffect, useState } from 'react';
import currency from 'currency.js';
import { FaTrash } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import InputMask from 'react-input-mask';
import SessionContext from 'contexts/session';
import SignInModal from 'components/SignInModal';
import { selectFreightAmount, selectIsCalculatingFreight } from '../redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setDeliveryData } from '../redux/slices/bills.slice';
import AddressSelector from './UserAddresses/CheckoutSelector';

interface ReviewOrderProps {
  isOpen: boolean;
  onClose: (props: AddItemToComanda) => void;
  showOrder?: boolean;
}

export default function ReviewOrder({ isOpen, onClose, showOrder = true }: ReviewOrderProps) {
  const dispatch = useAppDispatch();

  const toast = useToast();
  const session = useContext(SessionContext);
  const {
    isOpen: isLoginDialogOpen,
    onOpen: onLoginDialogOpen,
    onClose: onLoginDialogClose,
  } = useDisclosure();

  const {
    comandaRef,
    pendingOrder: pendingItems,
    billId,
    billType,
    venue,
    comanda,
    removeItemFromPendingOrder: removePendingItem,
    submitOrder,
  } = useContext(ComandaContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const metadata = useAppSelector((state) => state.bills.metadata[billId || '']);
  const calculatingFreight = useAppSelector(selectIsCalculatingFreight);
  const freightAmount = useAppSelector(selectFreightAmount);

  const setMetadata = (newMetadata: Pagcomanda.ComandaMetadata) => {
    dispatch(setDeliveryData({
      billId: billId || '',
      metadata: newMetadata,
    }));
  };

  const saveFormData = async () => {
    setIsLoading(true);

    if (comandaRef) {
      comandaRef
        .set({
          metadata,
        }, { merge: true });
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  const trySubmitOrder = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await saveFormData();

    setIsLoading(true);

    submitOrder(undefined, { metadata })
      .then(async (data) => {
        setIsLoading(false);
        onClose(data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title: 'Erro ao enviar pedido',
          description:
            err?.message ?? 'Erro desconhecido. Tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  let continueUrl = `/delivery/${venue?.id}#reviewOrder`;

  if (billType && billId) {
    continueUrl = `/${billType}/${billId}/menu#reviewOrder`;
  }

  const onCloseModal = () => {
    onClose({ Success: false });
  };

  const setMetadataField = (field: string, data: Pagcomanda.ComandaMetadataField) => {
    setMetadata({ ...metadata, [field]: data });
  };

  const roles = session
    .venues
    ?.find(
      (v) => v.data().venueId === venue?.id,
    )
    ?.data()
    .role;

  useEffect(() => {
    setMetadata(comanda?.metadata ?? {});
  }, [comanda?.metadata]);

  const pendingItemsObject = Object.fromEntries((pendingItems || []).map((v, i) => [i, v]));

  return (
    <>
      <Helmet>
        {isOpen && <title>{`Reveja seu pedido em ${venue?.data()?.CommercialName}`}</title>}
      </Helmet>
      <Modal isOpen={isOpen} onClose={onCloseModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={trySubmitOrder}>
          <ModalHeader>{showOrder ? 'Carrinho' : 'Dados da Comanda'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {showOrder && pendingItems && pendingItems.length > 0 && (
            <Stack spacing={2} divider={<Divider />}>
              {Object.keys(pendingItemsObject).map((itemKey) => {
                const item = pendingItemsObject[itemKey];
                return (
                  <Box
                    key={`pending-item-${itemKey}`}
                    px={3}
                  >
                    <Stack isInline>
                      <Flex marginRight="auto" alignItems="center" width="100%">
                        <Text fontSize="md">
                          <strong>
                            {item.qty}
                            {' '}
                            {item.unity}
                            {' '}
                            x
                            {' '}
                            {item.description}
                          </strong>
                        </Text>
                        <Text ml="auto" fontSize="md" fontWeight="bold">
                          {currency(item.price, { symbol: 'R$', decimal: ',', separator: '.' })
                            .add(item?.additionalPrice ?? 0).multiply(item.qty).format()}
                        </Text>
                      </Flex>
                      <IconButton
                        aria-label="Remover item do pedido"
                        icon={<FaTrash />}
                        isRound
                        size="sm"
                        variant="solid"
                        colorScheme="redComanda"
                        onClick={() => {
                          removePendingItem(parseInt(itemKey, 10));
                          if (pendingItems.length <= 1) onCloseModal();
                        }}
                      />
                    </Stack>
                    {((Array.isArray(item?.options)
                        && item.options.length > 0)
                        || (typeof item?.comments === 'string'
                          && item.comments.length > 0)) && (
                          <>
                            {Array.isArray(item?.options)
                            && item?.options.map((section) => (
                              <Box key={`section_${section?.name ?? 'Seção sem nome'}`}>
                                {(section?.options ?? []).map((option) => (
                                  <Text
                                    fontSize="xs"
                                    key={`description_${section?.name ?? 'Seção sem nome'}_${option?.title ?? option.description}`}
                                  >
                                    {option?.title ?? option?.description ?? 'Sem descrição'}
                                    {(option?.qty ?? 0) > 0 && ` x ${option.qty}`}
                                  </Text>
                                ))}
                              </Box>
                            ))}
                            {item?.comments && (
                            <>
                              <Text fontSize="xs">Observações</Text>
                              <Text fontSize="sm">
                                {item.comments.split('\n').map((itemLine) => (
                                  <React.Fragment
                                    key={`item-additional-info-${itemLine}`}
                                  >
                                    <strong>{itemLine}</strong>
                                    <br />
                                  </React.Fragment>
                                ))}
                              </Text>
                            </>
                            )}
                          </>
                    )}
                  </Box>
                );
              })}

              <Flex bgColor="yellow.200" p={3} borderRadius="md">
                {roles && roles.length > 0 && <Text fontWeight="black" mr="auto">Total:</Text>}
                {(!roles || roles.length === 0) && <Text fontWeight="black" mr="auto">Subtotal:</Text>}
                <Text fontWeight="black">
                  {pendingItems
                    .reduce(
                      (subtotal, item) => subtotal
                        .add(currency(item.price)
                          .add(item?.additionalPrice ?? 0))
                        .multiply(item.qty),
                      currency(0, { symbol: 'R$', decimal: ',', separator: '.' }),
                    ).format()}
                </Text>
              </Flex>
            </Stack>
            )}

            {showOrder && (!Array.isArray(pendingItems) || pendingItems.length <= 0) && (
            <Flex padding={5} justifyContent="center">
              <Text>Pedido vazio</Text>
            </Flex>
            )}

            {session?.userData && (
              <Flex direction="column" mt={3}>
                <Text mb={2}>Forma de entrega:</Text>
                <RadioGroup
                  defaultValue={metadata?.orderType?.value ?? 'delivery'}
                  onChange={(value) => {
                    let description = 'Delivery';
                    if (value === 'dinein') {
                      description = 'Consumir agora';
                    } else if (value === 'pickup') {
                      description = 'Retirar';
                    }

                    setMetadataField('orderType', {
                      value,
                      description,
                      label: 'Tipo do pedido',
                    });
                  }}
                >
                  <Stack direction="column" spacing={3}>
                    {session.usingVenue && (
                    <Radio value="dinein">
                      <Icon as={GiKnifeFork} />
                      {' '}
                      Consumir agora
                    </Radio>
                    )}
                    <Radio value="pickup">
                      <Icon as={GiShoppingBag} />
                      {' '}
                      Retirada
                    </Radio>
                    <Radio value="delivery">
                      <Icon as={MdPedalBike} />
                      {' '}
                      Delivery
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Stack w="100%" spacing={5} direction="column">
              {session?.userData && metadata?.orderType?.value === 'dinein' && (
              <FormControl marginBottom={4}>
                <FormLabel>Número da mesa *</FormLabel>
                <Input
                  errorBorderColor="red.300"
                  defaultValue={metadata?.tableNumber?.value ?? ''}
                  onChange={(e) => setMetadataField('tableNumber', {
                    value: e.target.value,
                    description: `Mesa ${e.target.value}`,
                    label: 'Número da mesa',
                  })}
                />
                <FormHelperText>
                  Em qual mesa devemos entregar o pedido?
                </FormHelperText>
              </FormControl>
              )}

              {session?.userData && metadata?.orderType?.value !== 'dinein' && roles && roles.length > 0 && (
                <>
                  <FormControl>
                    <FormLabel>Nome do cliente</FormLabel>
                    <Input
                      errorBorderColor="red.300"
                      defaultValue={metadata?.customerName?.value ?? ''}
                      onChange={(e) => setMetadataField('customerName', {
                        value: e.target.value,
                        description: e.target.value,
                        label: 'Nome do cliente',
                      })}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Telefone</FormLabel>
                    <Input
                      errorBorderColor="red.300"
                      as={InputMask}
                      mask={(metadata?.customerPhone?.value || '').replace(/\D/g, '').length > 10 ? '(99) 99999-9999' : '(99) 9999-99999'}
                      maskChar={null}
                      defaultValue={metadata?.customerPhone?.value ?? ''}
                      onBlur={(e) => setMetadataField('customerPhone', {
                        value: e.target.value,
                        description: e.target.value,
                        label: 'Telefone do cliente',
                      })}
                      onChange={(e) => setMetadataField('customerPhone', {
                        value: e.target.value,
                        description: e.target.value,
                        label: 'Telefone do cliente',
                      })}
                    />
                  </FormControl>
                  {(metadata?.orderType?.value ?? 'delivery') === 'delivery' && (
                  <FormControl>
                    <FormLabel>Endereço de entrega</FormLabel>
                    <Textarea
                      defaultValue={metadata?.customerAddress?.value ?? ''}
                      errorBorderColor="red.300"
                      onChange={(e) => setMetadataField('customerAddress', {
                        value: e.target.value,
                        description: e.target.value,
                        label: 'Endereço do cliente',
                      })}
                    />
                  </FormControl>
                  )}
                </>
              )}

              {session?.userData && (!roles || roles.length === 0) && metadata?.orderType?.value === 'delivery' && (
                <AddressSelector />
              )}

              {(!roles || roles.length === 0) && metadata?.orderType?.value === 'delivery' && calculatingFreight === 'succeeded' && freightAmount > 0 && (
              <Flex bgColor="green.200" p={3} borderRadius="md">
                <Text fontWeight="black" mr="auto">Total:</Text>
                <Text fontWeight="black">
                  {pendingItems && pendingItems
                    .reduce(
                      (subtotal, item) => subtotal
                        .add(currency(item.price)
                          .add(item?.additionalPrice ?? 0))
                        .multiply(item.qty),
                      currency(0, { symbol: 'R$', decimal: ',', separator: '.' }),
                    ).add(freightAmount).format()}
                </Text>
              </Flex>
              )}

              {!showOrder && (
                <Button
                  variant="solid"
                  colorScheme="redComanda"
                  type="button"
                  onClick={() => {
                    saveFormData()
                      .then(() => onCloseModal());
                  }}
                  size="lg"
                  isFullWidth
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Salvar dados
                </Button>
              )}

              {showOrder && session?.userData && (
              <Button
                variant="solid"
                colorScheme="redComanda"
                type="submit"
                size="lg"
                isFullWidth
                rightIcon={<Icon as={MdOutlineSend} />}
                isLoading={isLoading}
                disabled={
                isLoading
                || !Array.isArray(pendingItems)
                || pendingItems.length <= 0
                || ((!roles || roles.length === 0) && metadata?.orderType?.value === 'delivery' && calculatingFreight !== 'succeeded')
              }
              >
                Enviar pedido
              </Button>
              )}

              {showOrder && !session?.userData && (
                <Button
                  variant="solid"
                  colorScheme="redComanda"
                  marginTop={5}
                  type="button"
                  isFullWidth
                  onClick={onLoginDialogOpen}
                  disabled={
                    isLoading
                    || !Array.isArray(pendingItems)
                    || pendingItems.length <= 0
                  }
                  rightIcon={<Icon as={MdArrowForward} />}
                >
                  Fazer login para continuar
                </Button>
              )}
            </Stack>

          </ModalFooter>
        </ModalContent>
      </Modal>
      <SignInModal
        continueUrl={continueUrl}
        isOpen={isLoginDialogOpen}
        onClose={onLoginDialogClose}
      />
    </>
  );
}

ReviewOrder.defaultProps = {
  showOrder: true,
};
