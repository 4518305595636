import {
  analytics,
  db,
  firebaseAuth,
} from 'firebaseApp';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import firebase from 'firebase';
import { useAppDispatch } from '../redux/hooks';
import { loadVenue } from '../redux/slices/venue.slice';

interface SessionProviderProps {
  children: JSX.Element;
}

interface SessionContextProps {
  isReady: boolean;
  user?: firebase.User | null;
  cards?: firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Card>[];
  venues?: firebase.firestore.QueryDocumentSnapshot<Pagcomanda.VenueUser>[];
  venueData?: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>
  userData?: Pagcomanda.User;
  usingVenueRef?: firebase.firestore.DocumentReference<Pagcomanda.Venue>;
  usingVenue?: string | null;
  pwaPromptEvent?: Event;
  setUsingVenue: (venueId: string) => void;
}

const defaultProps: SessionContextProps = {
  isReady: false,
  usingVenueRef: undefined,
  setUsingVenue: () => {
    //
  },
};

const SessionContext = React.createContext<SessionContextProps>(defaultProps);

export const firestoreVenueConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Venue> = {
    toFirestore(venue: Pagcomanda.Venue) {
      return venue;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Venue {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Venue;
    },
  };

export const firestoreVenueUserConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.VenueUser> = {
    toFirestore(venue: Pagcomanda.VenueUser) {
      return venue;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.VenueUser {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.VenueUser;
    },
  };

export const firestoreCardUseConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Card> = {
    toFirestore(doc: Pagcomanda.Card) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Card {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Card;
    },
  };

export const firestoreAddressConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Address> = {
    toFirestore(doc: Pagcomanda.Address) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Address {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Address;
    },
  };

export const firestoreProductCategoriesConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.ProductCategory> = {
    toFirestore(doc: Pagcomanda.ProductCategory) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.ProductCategory {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.ProductCategory;
    },
  };

export const firestoreProductConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Product> = {
    toFirestore(doc: Pagcomanda.Product) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Product {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Product;
    },
  };

export const firestoreComandaConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.Comanda> = {
    toFirestore(doc: Pagcomanda.Comanda) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.Comanda {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.Comanda;
    },
  };

export const firestoreComandaItemsConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.ComandaItem> = {
    toFirestore(doc: Pagcomanda.ComandaItem) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.ComandaItem {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.ComandaItem;
    },
  };

export const firestorePaymentMethodsConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.PaymentMethod> = {
    toFirestore(doc: Pagcomanda.PaymentMethod) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.PaymentMethod {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.PaymentMethod;
    },
  };

export const firestoreUserConverter:
  firebase.firestore.FirestoreDataConverter<Pagcomanda.User> = {
    toFirestore(doc: Pagcomanda.User) {
      return doc;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Pagcomanda.User {
      const data = snapshot.data(options);

      if (!data) {
        throw new Error();
      }

      return data as Pagcomanda.User;
    },
  };

function SessionProvider({ children }: SessionProviderProps) {
  const [pwaPromptEvent, setPwaPromptEvent] = useState<Event>();
  const [user, setUser] = useState<firebase.User | null>();
  const [cards, setCards] = useState<firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Card>[]>();
  const [userData, setUserData] = useState<Pagcomanda.User>();
  const [
    venueData,
    setVenueData,
  ] = useState<firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>>();
  const [
    venues, setVenues,
  ] = useState<firebase.firestore.QueryDocumentSnapshot<Pagcomanda.VenueUser>[]>();
  const [usingVenue, setUsingVenue] = useState<string>();
  const [
    usingVenueRef,
    setUsingVenueRef,
  ] = useState<firebase.firestore.DocumentReference<Pagcomanda.Venue>>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && user.uid) {
      analytics.setUserId(user.uid);

      const unsubUser = db
        .collection('users')
        .doc(user.uid)
        .onSnapshot((doc: firebase.firestore.DocumentData) => {
          if (doc.exists) {
            const data = doc.data() as Pagcomanda.User;
            setUserData(data);
          }
        });

      const unsubCards = db
        .collection('users')
        .doc(user.uid)
        .collection('cards')
        .withConverter(firestoreCardUseConverter)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Pagcomanda.Card>) => {
          setCards(snapshot.docs);
        });

      const unsubVenues = db
        .collectionGroup('venueUsers')
        .withConverter(firestoreVenueUserConverter)
        .where('userId', '==', user.uid)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Pagcomanda.VenueUser>) => {
          if (!snapshot.empty) {
            setVenues(snapshot.docs);
          } else {
            setVenues([]);
          }
        });

      return () => {
        unsubUser();
        unsubCards();
        unsubVenues();
      };
    }

    return () => undefined;
  }, [user]);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((fbUser: firebase.User | null) => {
      setUser(fbUser);

      if (!fbUser || !fbUser.uid) {
        analytics.setUserId('');
        setUserData(undefined);
        setCards(undefined);
      }
    });

    window.addEventListener('beforeinstallprompt', (e) => {
      setPwaPromptEvent(e);
    });
  }, []);

  useEffect(() => {
    if (usingVenue) {
      dispatch(loadVenue(usingVenue));
      db
        .collection('venues')
        .withConverter(firestoreVenueConverter)
        .doc(usingVenue)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUsingVenueRef(doc.ref);
            setVenueData(doc);
          }
        });
    }
  }, [usingVenue]);

  const isReady: boolean = (typeof user?.uid === 'string' || user === null)
   && ((typeof user?.uid === 'string' && userData !== undefined) || user === null);

  const value = useMemo<SessionContextProps>(() => (
    {
      isReady,
      user,
      cards,
      venues,
      venueData,
      userData,
      usingVenueRef,
      usingVenue,
      pwaPromptEvent,
      setUsingVenue,
    }
  ), [
    isReady,
    usingVenue,
    user,
    userData,
    cards,
    venues,
    venueData,
    usingVenueRef,
    pwaPromptEvent,
  ]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

export default SessionContext;

export { SessionProvider };
