import {
  Outlet,
  useParams,
} from 'react-router-dom';
import { ComandaProvider } from 'contexts/comanda';
import React from 'react';

interface ComandaOutletParams {
  billType: Pagcomanda.BillType;
  billId: Pagcomanda.BillId;
  [key: string]: string;
}

function ComandaContextOutlet({ type }: {type: Pagcomanda.BillType}) {
  const { billType, billId, venueId } = useParams<ComandaOutletParams>();

  return (
    <ComandaProvider value={{ billType: billType || type || 'p', billId, venueId }}>
      <Outlet />
    </ComandaProvider>
  );
}

export default ComandaContextOutlet;
