import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  Controller,
  FieldValues,
  useForm,
} from 'react-hook-form';
import { FaMinus, FaPlus } from 'react-icons/fa';
import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import ComandaContext from 'contexts/comanda';
import currency from 'currency.js';
import firebase from 'firebase';
import FirebaseImage from 'components/FirebaseImage';
import { MdClose } from 'react-icons/md';
import SessionContext from 'contexts/session';
import CheckGroupSection from 'components/CheckGroupSection';
import { useAppSelector } from '../redux/hooks';
import { selectProducts } from '../redux/slices/venue.slice';

interface ItemSetupProps {
  productId?: string;
  product?: firebase.firestore.QueryDocumentSnapshot<Pagcomanda.Product>;
  isOpen: boolean;
  onClose: () => void;
}

interface ItemOption {
  id: string;
  qty?: number;
}

interface ItemSetupFields {
  options?: Array<Array<ItemOption>>;
  qty: number;
  tableNumber?: string;
  comments?: string;
  customFields: Array<Pagcomanda.CustomField>;
}

export default function ItemSetup({
  productId,
  product,
  isOpen = false,
  onClose = () => null,
}: ItemSetupProps) {
  const { addItemToPendingOrder, venue } = useContext(ComandaContext);
  const { venues } = useContext(SessionContext);
  const footerRef = useRef<HTMLElement>(null);
  const addRef = useRef<HTMLButtonElement>(null);
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const borderRadius = useBreakpointValue({ base: 'none', sm: 'md' });
  const allProducts = useAppSelector(selectProducts);

  const roles = venues
    ?.find(
      (v) => v.data().venueId === venue?.id,
    )
    ?.data()
    .role ?? [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm<ItemSetupFields>({
    reValidateMode: 'onChange',
  });

  const fields = watch();
  const { qty } = fields;

  let productData: Pagcomanda.Product | undefined;

  if (product?.id && typeof product.data === 'function') {
    productData = product.data();
  } else if (productId) {
    productData = allProducts.find((i) => i.id === productId)?.data;
  }

  const optionalItems = productData?.optionalItems ?? [];
  const defaultQty = productData?.defaultQty ?? 1;
  const minQty = productData?.minQty ?? defaultQty;

  let itemPrice = currency(productData?.price ?? 0);
  let additionalPrice = currency(0);
  let finalOptions: Array<Pagcomanda.ItemOptions> = [];

  if (Array.isArray(fields?.options) && Array.isArray(optionalItems)) {
    finalOptions = [];
    const filledSections: Array<Array<ItemOption>> = fields.options;

    filledSections.forEach((value, sectionId) => {
      if (Array.isArray(value)) {
        const filledOptions = value;

        filledOptions.forEach((option: ItemOption) => {
          const optId: number = parseInt(option.id, 10);
          if (!optionalItems[sectionId]?.options?.[optId]) return;
          const original = { ...(optionalItems[sectionId].options[optId]) };

          if (!finalOptions[sectionId]) {
            finalOptions[sectionId] = {
              ...optionalItems[sectionId],
              options: [],
            };
          }
          original.qty = option?.qty ?? 0;
          finalOptions[sectionId].options.push(original);
          if (original?.price && original.price >= 0 && !original.allowQty) {
            itemPrice = itemPrice.add(original.price);
          } else if (original?.price && original.price >= 0 && original.allowQty) {
            const currentAdditional = currency(original.price).multiply(option?.qty ?? 0);
            additionalPrice = additionalPrice.add(currentAdditional);
          }
        });
      }
    });
  }

  useEffect(() => {
    if (product?.id || productId) {
      reset({
        qty: defaultQty,
        comments: '',
      });
    }
  }, [defaultQty, product, productId, reset]);

  const submitItem = (values: FieldValues) => {
    const data = values as Pagcomanda.ComandaItem;

    addItemToPendingOrder({
      type: 'purchase',
      createdAt: new Date(),
      description: productData?.description ?? 'Produto sem descrição',
      productId: product?.id || productId,
      isServed: false,
      originalPrice: productData?.price,
      additionalPrice: additionalPrice.value * ((100 - (productData?.discount ?? 0)) / 100),
      price: itemPrice.value * ((100 - (productData?.discount ?? 0)) / 100),
      unity: productData?.unity ?? 'UN',
      hasPreparing: productData?.hasPreparing ?? false,
      hasService: productData?.hasService ?? false,
      category: productData?.category?.id,
      qty: data?.qty ?? productData?.defaultQty ?? 1,
      comments: data?.comments ?? '',
      tableNumber: data?.tableNumber ?? '',
      customFields: data?.customFields ?? [],
      options: finalOptions,
    });

    reset();
    onClose();
  };

  if ((!product?.id && !productId) || !isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      motionPreset="slideInBottom"
      onClose={onClose}
      size={modalSize}
      finalFocusRef={addRef}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        overflow="auto"
        as="form"
        onSubmit={handleSubmit(submitItem)}
        maxWidth="container.md"
        borderRadius={borderRadius}
      >
        <ModalHeader p={productData?.headerImage ? 0 : 4}>
          {productData?.headerImage && (
            <FirebaseImage
              storageUri={productData?.headerImage}
              width="100%"
              maxHeight="150px"
              objectFit="cover"
              objectPosition="50% 50%"
            />
          )}
          <Heading px={2} pt={3} size="md">
            {productData?.description ?? 'Produto'}
          </Heading>
          {(productData?.about || productData?.excerpt) && (
            <Text fontSize="sm" fontWeight="normal" mb={3} px={2} mt={1}>
              {productData?.excerpt || productData?.about}
            </Text>
          )}
        </ModalHeader>
        <IconButton
          aria-label="Cancelar item"
          position="absolute"
          variant="solid"
          top={5}
          right={3}
          fontSize="24px"
          colorScheme="redComanda"
          isRound
          icon={<MdClose />}
          onClick={onClose}
        />
        <ModalBody>
          {optionalItems.map((section, sectionKey) => (
            <Controller
              key={`optional_section_${section.name}`}
              name={`options.${sectionKey}`}
              control={control}
              rules={{
                validate: (opt) => {
                  const optSelected = Array.isArray(opt)
                    ? opt.reduce((acc, item) => acc + (item?.qty ?? 1), 0) : 0;

                  if (
                    (section?.minOptions ?? 0) === 0
                    && (section?.maxOptions ?? 0) === 0
                  ) {
                    return true;
                  }

                  if (section?.minOptions && optSelected < section?.minOptions) {
                    return `Selecione ao menos ${section.minOptions} ${section.minOptions > 1 ? 'opções' : 'opção'}.`;
                  }

                  if (section?.maxOptions && optSelected > section?.maxOptions) {
                    return `Selecione no máximo ${section.maxOptions} ${section.maxOptions > 1 ? 'opções' : 'opção'}.`;
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <CheckGroupSection
                  section={section}
                  sectionKey={sectionKey}
                  field={field}
                  errors={errors}
                  isDisabled={!((venue && venue.get('AllowConsumerOrder')) || roles?.length > 0)}
                  isInvalid={
                        section?.minOptions !== undefined
                        && errors?.options?.[sectionKey] !== undefined
                    }
                />
              )}
            />
          ))}

          {(venue && venue.get('AllowConsumerOrder')) && Array.isArray(productData?.customFields) && productData?.customFields.map((customField, index) => (
            <FormControl
              key={`customField_${customField.name}`}
              isInvalid={!!errors.customFields?.[index]}
              marginBottom={4}
            >
              <Input
                type="hidden"
                {...register(`customFields.${index}`, {
                  value: customField,
                })}
              />
              {customField.type !== 'CHECK' && (<FormLabel>{customField.label}</FormLabel>)}

              {customField.type === 'MULTILINE' && (
                <Textarea placeholder={customField.placeholder} {...register(`customFields.${index}.value`, customField.options)} errorBorderColor="red.300" />
              )}

              {customField.type === 'TEXT' && (
                <Input placeholder={customField.placeholder} {...register(`customFields.${index}.value`, customField.options)} errorBorderColor="red.300" />
              )}

              {customField.type === 'NUMBER' && (
                <Input type="number" placeholder={customField.placeholder} {...register(`customFields.${index}.value`, customField.options)} errorBorderColor="red.300" />
              )}

              {customField.type === 'CHECK' && (
                <>
                  <Checkbox isInvalid={!!errors.customFields?.[index]} {...register(`customFields.${index}.value`, customField.options)} errorBorderColor="red.300">
                    {customField.label}
                  </Checkbox>

                  {!!errors.customFields?.[index] && (
                  <FormHelperText>
                    Obrigatório marcar.
                  </FormHelperText>
                  )}
                </>
              )}

              {customField.helperText && (
                <FormHelperText>
                  {customField.helperText}
                </FormHelperText>
              )}
            </FormControl>
          ))}

          {((venue && venue.get('AllowConsumerOrder')) || roles?.length > 0) && (
          <FormControl isInvalid={!!errors.comments} marginBottom={4}>
            <FormLabel>Observações</FormLabel>
            <Textarea {...register('comments')} errorBorderColor="red.300" />
            <FormHelperText>
              Adicione informações extras sobre o item.
            </FormHelperText>
          </FormControl>
          )}
        </ModalBody>

        <ModalFooter
          display="flex"
          flexDirection="column"
          backgroundColor="gray.50"
          ref={footerRef}
        >
          <Box width="100%" mb={3}>
            {(productData?.discount ?? 0) <= 0 && (
              <Text fontSize="lg" mr="auto">
                Total:
                {' '}
                <strong>
                  {currency(itemPrice, { symbol: 'R$', decimal: ',', separator: '.' })
                    .add(additionalPrice)
                    .multiply(fields?.qty ?? 0)
                    .format()}
                </strong>
              </Text>
            )}

            {(productData?.discount ?? 0) > 0 && (
            <Text fontSize="lg" mr="auto">
              Total:
              {' '}
              de
              {' '}
              <strong style={{ textDecoration: 'line-through' }}>
                {currency(itemPrice, { symbol: 'R$', decimal: ',', separator: '.' })
                  .add(additionalPrice)
                  .multiply(fields?.qty ?? 0)
                  .format()}
              </strong>
              {' '}
              por
              {' '}
              <strong>
                {currency(itemPrice, { symbol: 'R$', decimal: ',', separator: '.' })
                  .add(additionalPrice).multiply((100 - (productData?.discount ?? 0)) / 100)
                  .multiply(fields?.qty ?? 0)
                  .format()}
              </strong>
            </Text>
            )}
          </Box>
          {!((venue && venue.get('AllowConsumerOrder')) || roles?.length > 0) && (
            <Alert status="warning">
              <AlertIcon />
              No momento este estabelecimento não está aceitando pedidos pelo pagcomanda.
            </Alert>
          )}
          {((venue && venue.get('AllowConsumerOrder')) || roles?.length > 0) && (
            <Flex direction="row" width="100%" alignItems="center">
              <FormControl
                width="140px"
                mr={3}
                size="sm"
                isInvalid={!!errors.qty}
              >
                <FormLabel hidden>Quantidade</FormLabel>
                <InputGroup size="md">
                  <InputLeftAddon
                    paddingX={2}
                    paddingY={0}
                    onClick={() => {
                      if (qty - minQty > minQty) {
                        setValue('qty', qty - minQty);
                      } else {
                        setValue('qty', minQty);
                      }
                    }}
                  >
                    <FaMinus />
                  </InputLeftAddon>
                  <Input
                    type="number"
                    min={minQty}
                    defaultValue={defaultQty}
                    step={minQty}
                    textAlign="center"
                    {...register('qty', {
                      required: true,
                      min: minQty,
                    })}
                    errorBorderColor="red.300"
                  />
                  <InputRightAddon
                    paddingX={2}
                    paddingY={0}
                    onClick={() => setValue('qty', qty + minQty)}
                  >
                    <FaPlus />
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage color="red.300">
                  {errors
                  && errors.qty
                  && errors.qty.type === 'required'
                  && 'Preencha uma quantidade válida.'}
                  {errors
                  && errors.qty
                  && errors.qty.type === 'min'
                  && `A quantidade mínima para esse produto é ${minQty}.`}
                </FormErrorMessage>
              </FormControl>
              <Button
                ref={addRef}
                marginLeft="auto"
                colorScheme="redComanda"
                type="submit"
              >
                Adicionar ao pedido
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ItemSetup.defaultProps = {
  productId: undefined,
  product: undefined,
};
