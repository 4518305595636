import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import billsReducer from './slices/bills.slice';
import counterReducer from './slices/counter.slice';
import userReducer from './slices/user.slice';
import venueReducer from './slices/venue.slice';

const persistConfig = {
  key: 'pagcomanda:store',
  storage,
};

const rootReducer = combineReducers({
  counter: counterReducer,
  bills: billsReducer,
  user: userReducer,
  venue: venueReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store);
