import React, { useEffect, useState } from 'react';
import {
  Divider,
  Flex,
  Heading,
  Image,
  Skeleton,
  StyleProps,
  Text,
} from '@chakra-ui/react';
import firebase from 'firebase';
import fakeAvatar from '../assets/80x80.jpg';

interface VenueHeaderProps {
  venue?: firebase.firestore.DocumentSnapshot<Pagcomanda.Venue>;
  venueRef?: firebase.firestore.DocumentReference<Pagcomanda.Venue>;
  type?: 'complete' | 'simple';
  hideDivider?: boolean;
  styles?: StyleProps;
}

export default function VenueHeader({
  venue,
  venueRef,
  type = 'complete',
  hideDivider = false,
  styles = {},
}: VenueHeaderProps) {
  const [venueData, setVenueData] = useState<Pagcomanda.Venue>();

  useEffect(() => {
    if (venue && venue?.data && !venueData) {
      setVenueData(venue.data());
    }

    if (!venue?.data && !venueData && venueRef) {
      venueRef.get().then((doc) => {
        if (doc.exists) {
          setVenueData(doc.data());
        }
      });
    }
  }, [venue, venueData, venueRef]);

  if (!venueData && type !== 'simple') return null;

  if (!venueData && type === 'simple') {
    return (
      <Flex flexDirection="column" width="100%" padding={5}>
        <Skeleton width="80%" height={6} marginBottom={1} />
        <Skeleton width="50%" height={3} />
      </Flex>
    );
  }

  if (!venueData) {
    return (
      <Flex
        flexDirection="row"
        minHeight={65}
        marginBottom={3}
        width="100%"
        {...styles}
      >
        <Skeleton
          marginLeft={4}
          marginTop={3}
          width="60px"
          height="60px"
          rounded={100}
        />
        <Flex flexDirection="column" paddingLeft={5} justifyContent="center">
          <Skeleton width="100%" height="1.5em" marginBottom={1}>
            Carregando...
          </Skeleton>
          <Skeleton width="80%" height="0.8em" />
        </Flex>
      </Flex>
    );
  }

  if (type === 'simple') {
    return (
      <Flex flexDirection="column" width="100%">
        <Heading size="md" color="gray.800">
          {venueData?.CommercialName
            ?? venueData?.Name
            ?? 'Estabelecimento sem título'}
        </Heading>
        {venueData?.Subtitle && (
          <Text fontSize={12} color="gray.600">
            {venueData?.Subtitle}
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <>
      <Flex
        flexDirection="row"
        minHeight={75}
        paddingX={3}
        paddingTop={2}
        paddingBottom={0}
      >
        <Image
          alt={venueData.CommercialName}
          rounded={100}
          src={venueData.Avatar}
          objectFit="contain"
          fallbackSrc={fakeAvatar}
          width="60px"
          height="60px"
        />
        <Flex flexDirection="column" paddingLeft={5} justifyContent="center">
          <Heading size="md" color="gray.800">
            {venueData?.CommercialName
              ?? venueData?.Name
              ?? 'Estabelecimento sem título'}
          </Heading>
          {venueData?.Subtitle && (
            <Text fontSize={12} color="gray.600">
              {venueData?.Subtitle}
            </Text>
          )}
        </Flex>
      </Flex>
      {!hideDivider && <Divider />}
    </>
  );
}

VenueHeader.defaultProps = {
  venue: undefined,
  venueRef: undefined,
  type: 'complete',
  hideDivider: false,
  styles: {},
};
