import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { db } from 'firebaseApp';
import { firestoreProductCategoryUseConverter, firestoreProductUseConverter } from 'screens/VenueMenu';
import { firestoreVenueConverter } from 'contexts/session';
import type { RootState } from '../store';

type LoadingState = 'idle' | 'pending' | 'succeeded' | 'failed';

interface VenueState {
  venueId: string | null;
  venueData: Pagcomanda.Venue | null;
  loadingVenue: LoadingState;

  products: Array<{id: string, data: Pagcomanda.Product}>;
  loadingProducts: LoadingState;

  categories: Array<{id: string, data: Pagcomanda.ProductCategory}>;
  loadingCategories: LoadingState;
}

const initialState: VenueState = {
  venueId: null,
  venueData: null,
  loadingVenue: 'idle',

  products: [],
  loadingProducts: 'idle',

  categories: [],
  loadingCategories: 'idle',
};

export const loadCategories = createAsyncThunk(
  'venue/loadCategories',
  async (venueId: string) => db.collection('venues')
    .doc(venueId)
    .collection('productsCategories')
    .where('isPublished', '==', true)
    .orderBy('parsedName', 'asc')
    .withConverter(firestoreProductCategoryUseConverter)
    .get()
    .then((docs) => docs.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }))),
);

export const loadProducts = createAsyncThunk(
  'venue/loadProducts',
  async (venueId: string) => db.collection('venues')
    .doc(venueId)
    .collection('products')
    .where('isPublished', '==', true)
    .orderBy('parsedName', 'asc')
    .withConverter(firestoreProductUseConverter)
    .get()
    .then((docs) => docs.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }))),
);

export const loadVenue = createAsyncThunk(
  'venue/loadVenue',
  async (venueId: string, thunkApi) => db.collection('venues')
    .doc(venueId)
    .withConverter(firestoreVenueConverter)
    .get()
    .then((doc) => {
      if (!doc.exists || !doc.id) {
        throw new Error('Venue not found.');
      }

      thunkApi.dispatch(loadCategories(venueId));
      thunkApi.dispatch(loadProducts(venueId));

      return doc.data() as Pagcomanda.Venue;
    }),
);

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loadVenue.pending, (state) => ({
      ...state,
      ...initialState,
      loadingVenue: 'pending',
    }));
    builder.addCase(
      loadVenue.fulfilled,
      (state, action) => ({
        ...state, loadingVenue: 'succeeded', venueId: action.meta.arg, venueData: action.payload,
      }),
    );
    builder.addCase(loadVenue.rejected, (state) => ({ ...state, loadingVenue: 'failed' }));

    builder.addCase(loadCategories.pending, (state) => ({ ...state, loadingCategories: 'pending' }));
    builder.addCase(
      loadCategories.fulfilled,
      (state, action) => ({ ...state, loadingCategories: 'succeeded', categories: action.payload }),
    );
    builder.addCase(loadCategories.rejected, (state) => ({ ...state, loadingCategories: 'failed' }));

    builder.addCase(loadProducts.pending, (state) => ({ ...state, loadingProducts: 'pending' }));
    builder.addCase(
      loadProducts.fulfilled,
      (state, action) => ({ ...state, loadingProducts: 'succeeded', products: action.payload }),
    );
    builder.addCase(loadProducts.rejected, (state) => ({ ...state, loadingProducts: 'failed' }));
  },
});

export const {
  logout,
} = venueSlice.actions;

export const selectLoadingVenue = (state: RootState) => state.venue.loadingVenue;
export const selectVenue = (state: RootState) => state.venue;

export const selectLoadingCategories = (state: RootState) => state.venue.loadingCategories;
export const selectProductCategories = (state: RootState) => state.venue.categories;

export const selectLoadingProducts = (state: RootState) => state.venue.loadingProducts;
export const selectProducts = (state: RootState) => state.venue.products;
export const selectFavoriteProducts = createSelector([
  selectProducts,
], (
  products,
) => products.filter((p) => p.data.isFavorite));

export const selectCategoryId = (state: RootState, categoryId: string) => categoryId;
export const selectProductsByCategoryId = createSelector([
  selectCategoryId,
  selectProducts,
], (
  categoryId,
  products,
) => products.filter((p) => p.data.categoryId === categoryId));

export const selectProductId = (state: RootState, productId: string) => productId;
export const selectProductById = createSelector([
  selectProductId,
  selectProducts,
], (
  productId,
  products,
) => products.find((p) => p.id === productId));

export const selectHasLoadingActivity = createSelector([
  selectLoadingVenue,
  selectLoadingCategories,
  selectLoadingProducts,
], (
  loadingVenue,
  loadingCategories,
  loadingProducts,
) => [loadingVenue, loadingCategories, loadingProducts].some((v) => v === 'pending'));

export default venueSlice.reducer;
