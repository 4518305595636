import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { db, firebaseAuth, functions } from 'firebaseApp';
import { firestoreAddressConverter } from 'contexts/session';
import type { RootState } from '../store';

type StateAddresses = {
  id: string;
  data: Pagcomanda.Address;
};

interface UserState {
  isAddAddressModalOpen: boolean;
  savingAddress: 'idle' | 'pending' | 'succeeded' | 'failed';

  loadingAddresses: 'idle' | 'pending' | 'succeeded' | 'failed';
  addresses: StateAddresses[];

  deliveryAddress: null | StateAddresses;
  calculatingFreight: 'idle' | 'pending' | 'succeeded' | 'failed';
  freightError: null | string;
  freightAmount: number;

  profile: null | Pagcomanda.User;
  loadingProfile: 'idle' | 'pending' | 'succeeded' | 'failed';
  savingProfile: 'idle' | 'pending' | 'succeeded' | 'failed';
  isProfileDataModalOpen: boolean;
}

const initialState: UserState = {
  isAddAddressModalOpen: false,
  savingAddress: 'idle',
  loadingAddresses: 'idle',
  addresses: [],
  deliveryAddress: null,
  calculatingFreight: 'idle',
  freightError: null,
  freightAmount: 0,
  loadingProfile: 'idle',
  profile: null,
  savingProfile: 'idle',
  isProfileDataModalOpen: false,
};

export const loadAddresses = createAsyncThunk(
  'user/loadAddresses',
  async () => {
    if (!firebaseAuth.currentUser) return [];

    return db.collection('users')
      .doc(firebaseAuth.currentUser.uid)
      .collection('addresses')
      .withConverter(firestoreAddressConverter)
      .get()
      .then((docs) => docs.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }) as StateAddresses));
  },
);

export const setDeliveryAddress = createAsyncThunk(
  'user/setDeliveryAddress',
  async (address: StateAddresses) => {
    const fnCalculateDistance = functions.httpsCallable('calculateDistance');

    const result = await fnCalculateDistance({
      Address: `${address.data.street}, ${address.data.number}, ${address.data.district}, ${address.data.cityName}, ${address.data.stateInitials}, ${address.data.countryName}`,
    });

    return result;
  },
);

export const addDeliveryAddress = createAsyncThunk(
  'user/addDeliveryAddress',
  async (address: Pagcomanda.Address, thunkApi) => {
    if (!firebaseAuth.currentUser) return null;

    const insert = await db
      .collection('users')
      .doc(firebaseAuth.currentUser.uid)
      .collection('addresses')
      .add(address);

    await thunkApi.dispatch(loadAddresses());

    const state = thunkApi.getState() as RootState;
    const insertedAddress = state.user.addresses.find((i) => i.id === insert.id);
    if (insertedAddress) {
      await thunkApi.dispatch(setDeliveryAddress(insertedAddress));
    }

    return insert.id;
  },
);

export const deleteAddress = createAsyncThunk(
  'user/deleteAddress',
  async (id: string, thunkApi) => {
    if (!firebaseAuth.currentUser) return [];

    await db.collection('users')
      .doc(firebaseAuth.currentUser.uid)
      .collection('addresses')
      .doc(id)
      .delete();

    thunkApi.dispatch(loadAddresses());

    return true;
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    openAddAddressModal: (state) => ({
      ...state,
      isAddAddressModalOpen: true,
    }),
    closeAddAddressModal: (state) => ({
      ...state,
      isAddAddressModalOpen: false,
    }),
    resetDeliveryAddress: (state) => ({
      ...state,
      deliveryAddress: null,
      calculatingFreight: 'idle',
      freightError: null,
      freightAmount: 0,
    }),
    logout: (state) => ({
      ...state,
      ...initialState,
    }),

    openProfileDataModal: (state) => ({
      ...state,
      isProfileDataModalOpen: true,
      savingAddress: 'idle',
    }),
    closeProfileDataModal: (state) => ({
      ...state,
      isProfileDataModalOpen: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(addDeliveryAddress.pending, (state) => ({ ...state, savingAddress: 'pending' }));
    builder.addCase(
      addDeliveryAddress.fulfilled,
      (state) => ({ ...state, savingAddress: 'succeeded', isAddAddressModalOpen: false }),
    );
    builder.addCase(addDeliveryAddress.rejected, (state) => ({ ...state, savingAddress: 'failed' }));

    builder.addCase(setDeliveryAddress.pending, (state) => ({
      ...state, calculatingFreight: 'pending', freightAmount: 0, freightError: null,
    }));
    builder.addCase(
      setDeliveryAddress.fulfilled,
      (state, action) => {
        const totalDistanceInMeters = action.payload.data?.routes?.[0]?.legs?.[0]?.distance.value
          ?? 0;

        const minimumFreight = 3;

        const totalFreight = totalDistanceInMeters / 650 < minimumFreight
          ? minimumFreight : Math.ceil(totalDistanceInMeters / 650);

        if (totalDistanceInMeters > 8000) {
          return {
            ...state,
            calculatingFreight: 'failed',
            freightError: `Endereço fora da área de entrega (${(totalDistanceInMeters / 1000).toFixed(0)}km).`,
            deliveryAddress: action.meta.arg,
            freightAmount: 0,
          };
        }

        return {
          ...state,
          calculatingFreight: 'succeeded',
          deliveryAddress: action.meta.arg,
          freightAmount: totalFreight,
        };
      },
    );
    builder.addCase(setDeliveryAddress.rejected, (state) => ({
      ...state, calculatingFreight: 'failed', freightAmount: 0, freightError: 'Erro ao calcular. Tente novamente.',
    }));

    builder.addCase(
      loadAddresses.pending,
      (state) => ({
        ...state,
        loadingAddresses: 'pending',
      }),
    );
    builder.addCase(
      loadAddresses.fulfilled,
      (state, action) => ({
        ...state,
        addresses: action.payload,
        loadingAddresses: 'succeeded',
      }),
    );
    builder.addCase(
      loadAddresses.rejected,
      (state) => ({
        ...state,
        loadingAddresses: 'failed',
      }),
    );
  },
});

export const {
  openAddAddressModal,
  closeAddAddressModal,
  resetDeliveryAddress,
  openProfileDataModal,
  closeProfileDataModal,
  logout,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectIsAddAddressModalOpen = (state: RootState) => state.user.isAddAddressModalOpen;

export const selectProfile = (state: RootState) => state.user.profile;
export const selectIsProfileDataModalOpen = (state: RootState) => state.user.isProfileDataModalOpen;
export const selectIsSavingProfile = (state: RootState) => state.user.savingProfile;

export const selectLoadingAddresses = (state: RootState) => state.user.loadingAddresses;
export const selectUserAddresses = (state: RootState) => state.user.addresses;

export const selectSavingAddress = (state: RootState) => state.user.savingAddress;

export const selectIsCalculatingFreight = (state: RootState) => state.user.calculatingFreight;
export const selectDeliveryAddress = (state: RootState) => state.user.deliveryAddress;
export const selectFreightError = (state: RootState) => state.user.freightError;
export const selectFreightAmount = (state: RootState) => state.user.freightAmount;

export default userSlice.reducer;
